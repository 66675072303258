<template>
<div>
    <!-- Header -->
    <div class="header_marketpalce_container">
    <div class="header_marketplace_title">
        Welcome to Advanced Primary Care - Powered by Apaly
    </div>
    <div class="header_marketplace_sub">
        Meet your personal provider today
    </div>
    <div class="header_marketpalce_inputs">
        <div class="header_marketplace_search_cont">
            <img src="/svg/icon_map_check.svg">
            <input v-model="address" type="text" id="pac-input" class="header_marketplace_search" placeholder="Search by address">
        </div>
        <button @click="searchAddress" class="header_icon_btn">
            <img src="/svg/icon_search.svg">
        </button>
    </div>
    </div>

    <!-- Contents -->
    <div class="welcome_contents">
        <div class="welcome_section">
            <div class="welcome_title">Search, compare and select your provider</div>
            <div class="welcome_sub">Choose from 1200+ clinics and 1000+ providers nationwide</div>
            <div class="welcome_map_cont" @click="searchAddress">
                <GmapMap
                    :center="center"
                    :zoom="12"
                    clickableIcons: false
                    style="width: 100%"
                    class="welcome_map"
                    ref="gmap"
                    :options="mapOptions"
                >
                    <GmapMarker :position="userCenter" />

                    <!-- <GmapMarker
                        v-for="(place, index) in places"
                        :key="index"
                        :position="place.coordinates"
                        :icon="{ url: '/dark-blue.png', scaledSize: { width: 40, height: 60 }, }"
                    /> -->

                    <GmapMarker
                        v-for="(place, index) in places"
                        :key="index"
                        :position="place.coordinates"
                        @click="openCard(place)"
                        :icon="{
                            url: place.newPatients ? '/dark-blue.png' : '/dark-gray.png', 
                            scaledSize: { width: 40, height: 60 }
                        }"
                    />
                </GmapMap>
            </div>
        </div>

        <div class="welcome_section margin_b">
            <div class="welcome_title">Get started with 3 easy steps</div> <br><br>
            <div class="welcome_row">
                <div class="welcome_col display_flex display_flex_center">
                    <div class="welcome_step_cont">
                        <img class="welcome_step_img" src="/illus_user.svg"/>
                        <div class="welcome_step_bg">
                            <div class="welcome_step_text">
                                Have your insurance card and dependent information handy
                            </div>
                        </div>
                    </div>
                </div>
                <div class="welcome_col display_flex display_flex_center">
                    <div class="welcome_step_cont">
                        <img class="welcome_step_img" src="/illus_location.svg"/>
                        <div class="welcome_step_bg extra_bg">
                            <div class="welcome_step_text">
                                Find a provider near you and create your account 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="welcome_col display_flex display_flex_center">
                    <div class="welcome_step_cont">
                        <img class="welcome_step_img" src="/illus_doctors.svg"/>
                        <div class="welcome_step_bg">
                            <div class="welcome_step_text">
                                <!-- Have a health assessment check-in with your provider to activate your membership -->
                                Meet your provider virtually or in person to activate your membership 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="welcome_section welcome_CTAs">
            <div class="welcome_row">
                <div class="welcome_col"><button @click="goToMarketplace()" class="welcome_CTA cta_primary">Get Started</button></div>
                <div class="welcome_col"><button @click="$router.push('/login')" class="welcome_CTA cta_secondary">Login</button></div>
            </div>
        </div>

        <!-- Footer -->
        <div class="welcome_footer">
            <div class="welcome_row">
                <div class="welcome_col">
                    <div class="welcome_foot_div">
                        <img class="welcome_logo" src="/apalywhite.svg"/>
                        <div class="welcome_foot_title">Advanced  Primary Care</div> <br>
                        <div class="welcome_foot_title">”It's like having a doctor in your family”</div>
                        <div class="welcoem_foot_small"> <br>
                            Apaly is a software platform and B2B marketplace that connects employers, TPAs, and carriers with point solution vendors and providers to streamline the business of healthcare.
                        </div>
                    </div>
                </div>
                <div class="welcome_col">
                    <div class="welcome_foot_div">
                        <div class="welcome_foot_title">Contact Us</div>
                        <div class="welcoem_foot_small">813-938-6626 | info@apaly.com</div> <br>
                        <div class="welcome_foot_icons">
                            <img src="/svg/icon_soc_fb.svg" @click="openSoc('fb')"/>
                            <img src="/svg/icon_soc_in.svg" @click="openSoc('in')"/>
                        </div>
                        <br>
                        <div class="welcoem_foot_small">
                            <strong>Disclaimer:</strong> Please note that access to Apaly Health is exclusive to employees whose employers have partnered with Advanced Primary Care providers. If your employer is not yet offering this benefit, please contact us at <span>info@apaly.com</span>, and our team will reach out to your employer about offering APC.
                        </div> <br><br>
                        <div class="welcoem_foot_small">
                            Looking to partner with Apaly? To learn more about how we work with Healthcare Providers, TPAs, and employers visit our 
                            <a href="https://apaly.com/">website</a>.
                        </div> <br><br>
                    </div>
                </div>
            </div>
            <div class="welcome_row">
                <div class="welcome_col">
                    <div class="welcome_foot_last">
                        <div class="welcome_copyright">COPYRIGHT &copy; {{ currentYear }} APALY HEALTH INC.</div>
                        <div class="welcom_links">
                            <a href="https://apaly.app/apaly-privacy-policy/">Privacy Policy</a>
                        </div>
                        <div class="welcom_links">
                            <a href="https://apaly.app/apaly-cookie-policy/">Cookie Policy</a>
                        </div>
                        <div class="welcom_links">
                            <a href="https://apaly.app/apaly-health-inc-term-and-conditions/">Terms of Use</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import api from "../../../../services/api";
import { mapState } from "vuex";
import axios from "axios";

export default {
data() {
    return {
        currentYear: "",
        address: "",
        mapOptions: {
            disableDefaultUi: true, // Disable the default UI
            mapTypeControl: false, // Disable the map type control (including Satellite view)
            fullscreenControl: false, // Disable fullscreen button
        },
    }
},
async created() {
    // IF logged in, redirect to marketpalce
    if(this.loggedIn) {
        this.$router.push("/marketplace");
    }
    // Load Google Maps API script
    // const script = document.createElement('script');
    // script.defer = true;
    // document.head.appendChild(script);

    this.$store.commit("setSearchAddress", "");

    const ipResponse = await axios.get(
        "https://api64.ipify.org/?format=json"
    );

    if (ipResponse.status === 200) {
        this.Ip = ipResponse.data.ip;
        const IP_ADDRESS = ipResponse.data.ip;

        const locationResponse = await fetch(
          `https://ipinfo.io/${IP_ADDRESS}/loc?token=c0bab02a62645b`
        );
        const data = await locationResponse.text();

        const [latitude, longitude] = data.split(",");
        this.$store.commit("setUserLocation", {
          lng: parseFloat(longitude),
          lat: parseFloat(latitude),
        });
    }

    localStorage.setItem("addFam", "no");

    // Current Year for teh footer
    this.currentYear = new Date().getFullYear();
},
watch: {
    places(nV, oV) {
      const falseCount = this.places.filter(place => place.newPatients === false).length;
      const trueCount = this.places.filter(place => place.newPatients === true).length;
    //   console.log("Number of places with newPatients set to false:", falseCount);
    //   console.log("Number of places with newPatients set to true:", trueCount);
    //   console.log("Total Number of places:", this.places.length);
    },
},
mounted() {
    // Get Google
    const timer = setInterval(() => {
        if (typeof google !== "undefined") {
            clearInterval(timer);
            this.setUpGoogleMaps();
        }
    }, 100);

    // Get Map Pins
    this.$store.dispatch("getAllPlaces");
},
methods: {
    openSoc(soc) {
        if(soc == 'fb') {
            window.open(`https://www.facebook.com/apalyhealth`, '_blank')
        }
        else if(soc == 'in') {
            window.open(`https://www.linkedin.com/company/apaly`, '_blank')
        }
    },
    searchAddress() {
      this.$store.commit("setSearchAddress", this.address);
      this.goToMarketplace();
    },
    goToMarketplace() {
        this.$router.push("/marketplace").catch(err => {
            if (err.name !== 'NavigationDuplicated') {
            throw err;
            }
        });
    },
    setUpGoogleMaps() {
        // alert();
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("pac-input"),
            { types: ["geocode"] }
        );
        this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    },
    handlePlaceChanged() {
      const place = this.autocomplete.getPlace();
    //   console.log("place", place);

      if (place && place.formatted_address) {
        const selectedAddress = place.formatted_address;
        this.address = selectedAddress;
      }
      this.$store.commit("setSearchAddress", this.address);
      this.goToMarketplace();
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userPosition = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            // this.userCenter = userPosition;
            this.$store.commit("setUserLocation", userPosition);
            // this.openCard(userPosition);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported.");
      }
    },
    getEmployers() {
      api()
        .get("/member/app/potential/employers")
        .then((res) => {
          this.employers = res.data;
        });
    },
},
computed: {
    ...mapState({
        searchBy: (state) => state.searchBy,
        marketplace: (state) => state.marketplace,
        clinics: (state) => state.clinics,
        clinicians: (state) => state.clinicians,
        totalPages: (state) => state.totalPages,
        lastPageNumber: (state) => state.pageNumber,
        center: (state) => state.mapCenter,
        userCenter: (state) => state.userLocation,
        loader: (state) => state.loader,
        places: (state) => state.mapPins,
        memberInfo: (state) => state.memberInfo,
    }),
    loggedIn() {
      if(this.$cookies.get("token")) {
        return true
      }
      else {
        return false
      }
    },
}
}
</script>

<style>

</style>