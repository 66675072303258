<template>
  <v-app>
    <NoAuthHeader v-if="!$cookies.get('token') &&

      $route.path == '/marketplace' &&
      $route.path != '/confirm-plan-information' &&
      $route.path != '/add-family-member' &&
      $route.path != '/register' &&
      $route.path != '/login' &&
      $route.path != '/confirm-info' &&
      $route.path != '/verify' &&
      $route.path != '/auth/reset-password' &&
      $route.path != '/forgot-password'
      && $route.path != '/account/edit-subscriber'
      && !$route.path.includes('/account/edit-dependent') 
      && !$route.path.includes('/account/edit-contact') 
      " @drawer="openSideBar"></NoAuthHeader>
    <AuthHeader v-if="$cookies.get('token') &&
      $route.path != '/membership' &&
      $route.path != '/confirm-plan-information' &&
      $route.path != '/add-family-member' &&
      $route.path != '/confirm-info' &&
      $route.path != '/verify' &&
      $route.path != '/register' &&
      $route.path != '/auth/reset-password' &&
      $route.path != '/forgot-password'
      && $route.path != '/account/edit-subscriber'
       && !$route.path.includes('/account/edit-dependent')
       && !$route.path.includes('/account/edit-contact') 
      " style="border-bottom: 1px solid #cccccc" @drawer="openSideBar"></AuthHeader>
    <SideBar v-if="showComponents &&
      !showMobile &&
      $route.path != '/membership' &&
      $route.path != '/confirm-plan-information' &&
      $route.path != '/add-family-member' &&
      $route.path != '/register' &&
      $route.path != '/login' &&
      $route.path != '/confirm-info' &&
      $route.path != '/verify' &&
      $route.path != '/auth/reset-password'
      && $route.path != '/forgot-password'
      && $route.path != '/account/edit-subscriber'
       && !$route.path.includes('/account/edit-dependent') 
       && !$route.path.includes('/account/edit-contact') 
      " style="top: 50px; width: 225px"></SideBar>
    <div :style="showComponents &&
        !showMobile &&
        $route.path != '/membership' &&
        $route.path != '/confirm-plan-information' &&
        $route.path != '/add-family-member' &&
        $route.path != '/register' &&
        $route.path != '/login' &&
        $route.path != '/confirm-info' &&
        $route.path != '/verify' &&
        $route.path != '/auth/reset-password' &&
        $route.path != '/forgot-password'
        && $route.path != '/account/edit-subscriber'
       && !$route.path.includes('/account/edit-dependent') 
       && !$route.path.includes('/account/edit-contact') 
        ? 'padding-left: 225px; padding-top:58px !important;'
        : ''
      ">
      <router-view></router-view>
    </div>
    <v-navigation-drawer v-if="$cookies.get('token')" v-model="drawer" temporary top
      style="position: absolute; top: 0; z-index: 9999">
      <v-list density="compact" nav>
        <v-list-item>
          <img src="/apaly-logo.png" width="90px" height="auto" class="mb-2 mt-2"/>
          <!-- <v-icon @click="drawer = !drawer" class="ml-auto">mdi-close</v-icon> -->
        </v-list-item>
        <v-divider horizontal> </v-divider>
        <v-list-item @click="openPage('home')" class="d-flex">
          <svg data-v-3830fcac="" width="24" height="20" viewBox="0 0 17 18" fill="none"
            xmlns="http://www.w3.org/2000/svg" class="mt-1">
            <path data-v-3830fcac=""
              d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
              stroke="#0069F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          <span class="ml-2 font-16 font-weight-400" style="color: #0069f3">
            Home</span>
        </v-list-item>
        <v-divider horizontal> </v-divider>
        <v-list-item @click="openPage('marketplace')" class="d-flex">
          <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.8382 7.00365L20.4107 1.00365C20.1969 0.632402 19.7994 0.399902 19.3682 0.399902H4.63064C4.19939 0.399902 3.80189 0.632402 3.58814 1.00365L0.160638 7.00365C-0.296863 7.80615 0.276888 8.7999 1.19939 8.7999H2.39939V18.3437C2.39939 19.0374 2.93564 19.5999 3.59939 19.5999H13.1994C13.8632 19.5999 14.3994 19.0374 14.3994 18.3437V8.7999H20.3994V19.2999C20.3994 19.4649 20.5344 19.5999 20.6994 19.5999H21.2994C21.4644 19.5999 21.5994 19.4649 21.5994 19.2999V8.7999H22.7994C23.7219 8.7999 24.2957 7.80615 23.8382 7.00365ZM13.1994 18.3437C13.1994 18.3774 13.1882 18.3962 13.1919 18.3999L3.62189 18.4074C3.62189 18.4074 3.59939 18.3887 3.59939 18.3437V13.5999H13.1994V18.3437ZM13.1994 12.3999H3.59939V8.7999H13.1994V12.3999ZM1.20314 7.5999L4.63064 1.5999H19.3682L22.7994 7.5999H1.20314Z"
              fill="#0069F3" />
          </svg>
          <span class="ml-2 font-16 font-weight-400" style="color: #0069f3">
            Marketplace</span>
        </v-list-item>
        <v-divider horizontal> </v-divider>
        <v-list-item @click="openPage('account')">
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.3596 11.1999C11.2834 11.1999 10.7659 11.7999 8.99961 11.7999C7.23336 11.7999 6.71961 11.1999 5.63961 11.1999C2.85711 11.1999 0.599609 13.4574 0.599609 16.2399V17.7999C0.599609 18.7937 1.40586 19.5999 2.39961 19.5999H15.5996C16.5934 19.5999 17.3996 18.7937 17.3996 17.7999V16.2399C17.3996 13.4574 15.1421 11.1999 12.3596 11.1999ZM16.1996 17.7999C16.1996 18.1299 15.9296 18.3999 15.5996 18.3999H2.39961C2.06961 18.3999 1.79961 18.1299 1.79961 17.7999V16.2399C1.79961 14.1212 3.52086 12.3999 5.63961 12.3999C6.37461 12.3999 7.10586 12.9999 8.99961 12.9999C10.8896 12.9999 11.6246 12.3999 12.3596 12.3999C14.4784 12.3999 16.1996 14.1212 16.1996 16.2399V17.7999ZM8.99961 9.9999C11.6509 9.9999 13.7996 7.85115 13.7996 5.1999C13.7996 2.54865 11.6509 0.399902 8.99961 0.399902C6.34836 0.399902 4.19961 2.54865 4.19961 5.1999C4.19961 7.85115 6.34836 9.9999 8.99961 9.9999ZM8.99961 1.5999C10.9834 1.5999 12.5996 3.21615 12.5996 5.1999C12.5996 7.18365 10.9834 8.7999 8.99961 8.7999C7.01586 8.7999 5.39961 7.18365 5.39961 5.1999C5.39961 3.21615 7.01586 1.5999 8.99961 1.5999Z"
              fill="#0069F3" />
          </svg>

          <span class="ml-2 font-16 font-weight-400" style="color: #0069f3">
            Account</span>
        </v-list-item>
        <v-divider horizontal> </v-divider>
        <v-list-item>
          <v-icon color="#0069F3">mdi-logout</v-icon>

          <span class="ml-2 font-16 font-weight-400" style="color: #0069f3" @click="logout()">
            Sign Out</span>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import NoAuthHeader from "@/components/Sections/DPCMember/Layout/Noauth/Header";
import AuthHeader from "@/components/Sections/DPCMember/Layout/AuthedHeader";
import SideBar from "@/components/Sections/DPCMember/Layout/sideMenu";
export default {
  data() {
    return {
      drawer: null,
      cares: [],
      filters: {
        name: "",
        type: [],
        covered: false,
        review: "all",
        windowWidth: window.innerWidth,
      },
      // showComponents: false,
      showMobile: false,
    };
  },
  computed: {
    // ...mapGetters(["getComponentState"]),
    ...mapState({
      showComponents: (state) => state.showSidebar,
      memberInfo: (state) => state.memberInfo
    }),
  },
  watch: {
    getComponentState() {
      this.showComponents = this.memberInfo && this.$cookies.get("token");
    },
  },
  components: {
    NoAuthHeader,
    SideBar,
    AuthHeader,
  },
  mounted() {
    // let Script = document.createElement("script");
    // Script.setAttribute("src", "https://desk.zoho.com/portal/api/web/inapp/318353000002184001?orgId=675143780");
    // Script.setAttribute("nonce", "");
    // Script.setAttribute("defer", "defer");
    // document.head.appendChild(Script);
    // this.showComponents = localStorage.getItem("user") && this.$cookies.get("token");

    //code refactoring issue #553(api)
    if(this.$cookies.get("token")){
      this.$store.dispatch('getMemberInfo');
    }

    // SEE IF LOGGED IN (IF TOKEN EXIST)
    if (this.memberInfo && this.$cookies.get("token")) {
      this.$store.commit("showSidebar", true);
    } else {
      this.$store.commit("showSidebar", false);
    }
    // See if Member Info has data. If not, the redirect to login
    // UPDATE: Remove this to enable non registered users experience
    // if(Object.keys(this.memberInfo).length == 0) {
    //   this.logout();
    // }
    
    
  },

  created() {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 600) {
      this.showMobile = true;
    } else {
      this.showMobile = false;
    }
    // this.showComponents = localStorage.getItem("user") && this.$cookies.get("token");
  },
  methods: {
    openPage(page) {
      // Empty Dependent ID from Store
      this.$store.commit("setDependent", "");

      this.$router.push("/" + page).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    openSideBar(event) {
      this.drawer = event;
    },
    logout() {
      localStorage.clear();
      this.$cookies.remove("token");
      this.$store.commit("setComponentState", false);
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss"></style>
