<template>
  <div class="page-background">
    <main-header :title="'Personal'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <v-form class="forma" v-model.trim="valid" ref="formEditMember">
        <div>
          <h3 class="from-headers">Personal Information</h3>
          <v-text-field outlined label="First Name" dark class="input-text" v-model="member.firstName" :rules="requiredRules"></v-text-field>
          <v-text-field outlined label="Last Name" dark v-model="member.lastName" :rules="requiredRules"></v-text-field>
          <v-text-field dark type="date" outlined label="Date of Birth" v-model="member.dob"></v-text-field>
          <v-select  dark
            :items="genders"
            item-title="text"
            item-value="value"
            label="Select"
            outlined
            v-model="member.gender"
            :rules="requiredRules"></v-select>
        </div>

        <div>
          <h3 class="from-headers">Contact</h3>
          <v-text-field type="email" outlined label="Email" dark  v-model="member.email" :rules="emailRules"></v-text-field>
          <v-text-field type="tel" outlined label="Phone" placeholder="000-000-0000 " dark v-model="member.phone" :rules="requiredRules" ></v-text-field>
          <v-text-field dark type="number" placeholder="00000" outlined label="Home Zip Code" v-model="member.zipCode" :rules="requiredRules"></v-text-field>
        </div>

        <div>
          <h3 class="from-headers">
            Insurance Information <br />
            <span class="from-sub-headers">(from your insurance card)</span>
          </h3>
          <v-text-field outlined label="Member ID" dark placeholder="000-000000" v-model="member.memberId" :rules="requiredRules"></v-text-field>
          <v-text-field outlined label="Group ID" placeholder="000-000000" dark v-model="member.groupId" :rules="requiredRules"></v-text-field>
          <v-text-field outlined label="Payer ID" placeholder="000-000000" dark v-model="member.payerId" :rules="requiredRules"></v-text-field>
          <v-select dark
            :items="relationships"
            label="Select"
            outlined
            item-title="text"
            item-value="value"
            v-model="member.subscriberRelation"></v-select>
        </div>
      </v-form>

    </div>
    <div class="d-flex footer"  style="">
        <div class="col-6">
            <v-btn class="footer-btn-cancel" >Cancel</v-btn>
        </div>
        <div class="col-6">
            <v-btn color="white" class="footer-btn" @click="submit()">Save</v-btn>
        </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import moment from "moment";
import api from "@/services/api";

export default {
  data() {
    return {
      member:JSON.parse(localStorage.getItem("user")) ,
      relationships: [
        {
          text:"Self",
          value: "Self",
        },
        {
          text:"Spouse",
          value: "Spouse",
        },
      ],
      genders: [
        {
          value: "m",
          text: "Male",
        },
        {
          value: "f",
          text: "Female",
        },
      ],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      };
  },
  components: {
    mainHeader,
  },
  created() {
    console.log('member',this.member)
    this.member.dob = moment.utc( this.member.dob).format("YYYY-MM-DD");
  },
  methods: {
    submit(){
      this.$refs.formEditMember.validate();
      if (this.valid){
        let data = {
          firstName:this.member.firstName,
          lastName:this.member.lastName,
          email:this.member.email,
          gender:this.member.gender,
          zipCode:this.member.zipCode,
          phone:this.member.phone,
          dob:this.member.dob,
        }
        api().put(`/member/app/family/member/${this.member._id}`, data)
          .then((res) => {
           console.log('res from update',res)
           this.$router.push('/account/alert/update');
          }).catch((err)=>{
            console.log("err from update", err);
      });
      }
    }
  },

};
</script>
<style scoped lang="scss">
.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 150px !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background {

  background-color: #001e46;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-subtitle {
  font-family: "Quicksand";
  font-weight: 600;
}

.second-subtitle {
  color: #001e46;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.mobile-edit-form v-text-field {
  color: white;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
    background-color: #16407a;
    height:80px;
    position:absolute;
    bottom:0;
    width:100%;
    align-items: flex-start;
    border-top: 1px solid #C9C9C9
}
.footer-btn{
    border-radius: 23px;
    width: 100%;
}

.footer-btn-cancel{
    background-color:#16407a !important;
    border-radius: 23px;
    width: 100%;
    border: 1px solid white !important;
    color: white;
    float:right
}



// .input-text {
//         ::v-deep {
//             .v-text-field {
//                 input {
//                     color: red;
//                 }
//             }
//         }
//     }
.forma v-text-field{
    color: red;
}
</style>
