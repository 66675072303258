import DPCMemberIndex from '../views/DPCMember.vue'
import Home from '../components/Sections/DPCMember/Home/Home.vue'
import Marketplace from '../components/Sections/DPCMember/Marketplace/NewMarketplace.vue'
import AllItems from '../components/Sections/DPCMember/Marketplace/AllItems.vue'
import SolutionDetails from '../components/Sections/DPCMember/Marketplace/VirtualNetworkDetails.vue'
import ProviderDetails from '../components/Sections/DPCMember/Marketplace/NewProviderDetails.vue'
import ClinicianDetails from '../components/Sections/DPCMember/Marketplace/ClinicianDetails.vue'
import VendorDetails from '../components/Sections/DPCMember/Marketplace/VendorDetails.vue'
import AccountDetails from '../components/Sections/DPCMember/Account/Details.vue'
import EditSubscriber from '../components/Sections/DPCMember/Account/EditSubscriberInfo.vue'
import EditDependent from '../components/Sections/DPCMember/Account/EditDependentInfo.vue'
import EditContactInformation from '../components/Sections/DPCMember/Account/EditContactInformation.vue'
import FamilyMembersMenu from '../components/Sections/DPCMember/Account/FamilyMembers/Menu.vue'
import FamilyMember from '../components/Sections/DPCMember/Account/FamilyMembers/FamilyMember.vue'
import FamilyMembersIndex from '../components/Sections/DPCMember/Account/FamilyMembers/Index.vue'
import Personal from '../components/Sections/DPCMember/Account/Personal.vue'
import PersonalEdit from '../components/Sections/DPCMember/Account/PersonalEdit.vue'
import FindCare from '../components/Sections/DPCMember/FindCare/Home.vue'
import FindCareSearch from '../components/Sections/DPCMember/FindCare/Search.vue'
import FindCareSearchFilters from '../components/Sections/DPCMember/FindCare/Filters.vue'
import FamilyMemberAdd from '../components/Sections/DPCMember/Account/FamilyMembers/Add.vue'
import FamilyMemberEdit from '../components/Sections/DPCMember/Account/FamilyMembers/Edit.vue'
import AlertPage from '../components/Sections/DPCMember/Account/AlertPage.vue'
import Settings from '../components/Sections/DPCMember/Account/Settings.vue'
import SettingsChangePassword from "@/components/Sections/DPCMember/Account/SettingsChangePassword";
import ContactUsIndex from "../components/Sections/DPCMember/ContactUs/Index.vue";
import ContactUs from "../components/Sections/DPCMember/ContactUs/ContactUs.vue";
import AuthIndex from "../components/Sections/DPCMember/Auth/Index.vue";
import Entry from "../components/Sections/DPCMember/Auth/Entry.vue";
import Register from "../components/Sections/DPCMember/Auth/Register.vue";
import NewRegister from "../components/Sections/DPCMember/Auth/NewRegister.vue";
import ConfirmInformation from "../components/Sections/DPCMember/Auth/ConfirmInformation.vue";
import ConfirmInfo from "../components/Sections/DPCMember/Auth/ConfirmPlanInfo.vue";
import AddFamilyMember from "../components/Sections/DPCMember/Auth/AddFamilyMember.vue";
import Alert from '../components/Sections/DPCMember/Auth/Alert.vue'
import Login from '../components/Sections/DPCMember/Auth/Login.vue'
import NewLogin from '../components/Sections/DPCMember/Auth/NewLogin.vue'
import VerifyAccount from '../components/Sections/DPCMember/Auth/VerifyAccount.vue'
import ForgotPassword from '../components/Sections/DPCMember/Auth/ForgotPassword.vue'
import ResetPassword from '../components/Sections/DPCMember/Auth/ResetPassword.vue'
import ConfirmEmail from '../components/Sections/DPCMember/Auth/ConfirmEmail.vue'
import MemberChangePassword from '../components/Sections/DPCMember/Auth/MemberChangePassword.vue'
import MemberPersonalInfo from '../components/Sections/DPCMember/Auth/PersonalInfo.vue'
import AccountConfirmed from '../components/Sections/DPCMember/Auth/AccountConfirmed.vue'
import WelcomePage from '../components/Sections/DPCMember/Marketplace/Welcome'
import RouterView from '../components/Sections/DPCMember/Layout/RouterView.vue'
import notFound from '../components/Sections/DPCMember/Layout/notFound.vue'
import ExpiredLink from '../components/Sections/DPCMember/Auth/ExpiredLink.vue'
import MemberCard from '../components/Sections/DPCMember/Auth/MembershipCard.vue'

function routeGuard(to, from, next) {
    // MEM 521 | Authentication Refactoring
    // We will no longer use localStorage.user to get user/member info
    // Instead we get user/member info from store

    // What this basically do is, if localStorage.user and $cookies.get('token') are empty, we redirect them into the marketplace
    // Other wise, just proceed to any intendend route /the use of next()/

    // let user = JSON.parse(localStorage.getItem('user'));
    // console.log('user',user) 
    // if(!user){  // Why do this? K.N
    //     next('/marketplace');
    // }
    // else if ( user.type != 'dpc-member' || !$cookies.get('token')){  // When we can just do this without the user.type != 'dpc-member' K.N
    //     next('/marketplace');
    // }else{
    //     next();
    // }

    // To avoid using localStorage.user, we will just use the $cookies.get('token')
    if (!$cookies.get('token')){
        // next('/welcome');
        next('/signup');
    }else{
        next();
    }
}
const router = [

    // {
    //     path:'/login',
    //     name:'MemberLogin',
    //     component:Login,
    //     beforeEnter: (to, from, next) => {
    //         localStorage.clear();
    //         next();
    //       },
    // },
    {
        path:'/login',
        name:'MemberLogin',
        component:NewLogin,
        beforeEnter: (to, from, next) => {
            // localStorage.clear();
            next();
          },
    },
    {
        path:'/verify',
        name:'VerifyAccount',
        component:VerifyAccount,
    },
    {
        path:'/forgot-password',
        name:'MemberForgotPassword',
        component:ForgotPassword,
    },
    {
        path:'/expired',
        name:'ExpiredLink',
        component:ExpiredLink,
    },
    {
        path:'/auth/reset-password:token?',
        name:'MemberResetPassword',
        component:ResetPassword,
    },
    // {
    //     path:'/register',
    //     name:'MemberRegister',
    //     component:Register,
    //     beforeEnter: (to, from, next) => {
    //         localStorage.clear();
    //         next();
    //       },
    //     props: (route) => ({
    //         company: route.query.company || '',
    //       }),
    // },
    {
        path:'/register',
        name:'MemberRegister',
        component:NewRegister,
        beforeEnter: (to, from, next) => {
            localStorage.clear(); // 
            next();
          },
        props: (route) => ({
            company: route.query.company || '',
          }),
    },
    {
        path:'/confirm-plan-information',
        name:'Confirm Plan Information',
        component:ConfirmInformation,
        props: (route) => ({
            token: route.query.token || '',
          }),
    },
    {
        path:'/membership',
        name:'Membership Card',
        component:MemberCard,
        props: (route) => ({
            token: route.query.token || '',
          }),
    },
    {
        path:'/confirm-info',
        name:'Confirm Information',
        component:ConfirmInfo,
        props: (route) => ({
            token: route.query.token || '',
          }),
    },
    {
        path:'/add-family-member',
        name:'Add Family Member',
        component:AddFamilyMember,
        props: (route) => ({
            token: route.query.token || '',
          }),
    },
    // {
    //     path:'/welcome',
    //     name:'Welcome',
    //     component:WelcomePage,
    //     props: (route) => ({
    //         company: route.query.company || '',
    //         location: route.query.location || '',
    //       }),
    // },
    {
        path:'/marketplace',
        name:'Marketplace',
        component:Marketplace,
        props: (route) => ({
            company: route.query.company || '',
            location: route.query.location || '',
          }),
    },
    {
        path:'/marketplace/all/:category',
        name:'AllItems',
        component:AllItems,
    },
    {
        path:'/marketplace/solutions/details/:id',
        name:'Solutions Details',
        component:SolutionDetails,
    },
    {
        path:'/marketplace/provider/details/:id',
        name:'Provider Details',
        component:ProviderDetails,
    },
    {
        path:'/marketplace/vendor/details/:id',
        name:'Vendor Details',
        component:VendorDetails,
    },
    {
        path:'/marketplace/clinician/details/:id',
        name:'Clinican Details',
        component:ClinicianDetails,
    },
    {
        path:'/account/edit-subscriber',
        name:'Edit Subscriber`s Information',
        component:EditSubscriber,
    },
    {
        path:'/account/edit-dependent/:id',
        name:'Edit Dependent`s Information',
        component:EditDependent,
    },
    {
        path:'/account/edit-contact',
        name:'Edit Contact Information',
        component:EditContactInformation,
    },
    {
        path: '/404',
        component: notFound
    },  
    { 
        path: '*',
        redirect: '/404' 
    },  
    {  // DEFAULT PAGE
        path: '/',
        redirect: '/register',
        name:'NewRegister',
        component:NewRegister,
    },  
    {
        path:'/account',
        name:'account',
        beforeEnter: routeGuard,
        component:RouterView,
        redirect: () => {
            return { path: '/account/details'}
        },
        children:[
            {
                path:'details',
                name:'Account Details',
                component:AccountDetails,
                beforeEnter: routeGuard,
            },
            
            {
                path:'alert/:type',
                name:'alertPage',
                component:AlertPage,
            },
            {
                path:'personal',
                name:'accountFamilyMembers',
                component:Personal,
            },
            {
                path:'personal/edit',
                name:'personalEdit',
                component:PersonalEdit,
            },
            {
                path:'family-members',
                name:'familyMembersIndex',
                component:FamilyMembersIndex,
                redirect: () => {
                    return { path: '/account/family-members/menu'}
                },
                children:[
                    {
                        path:'menu',
                        name:'familyMembersMenu',
                        component:FamilyMembersMenu,
                    },
                    {
                        path:'family-member/:id',
                        name:'familyMember',
                        component:FamilyMember,
                    },
                    {
                        path:'add',
                        name:'addFamilyMember',
                        component:FamilyMemberAdd,
                    },
                    {
                        path:'edit/:id',
                        name:'editFamilyMember',
                        component:FamilyMemberEdit,
                    },
                ]
            },
            {
                path:'settings',
                name:'accountSettings',
                component:Settings,
            },
            {
                path:'settings/change-password',
                name:'accountSettingsChangePassword',
                component:SettingsChangePassword,
            }
        ]
    },
    {
        component:DPCMemberIndex,
        path:'',
        name:'dpcIndex',
        beforeEnter: routeGuard,
        redirect: () => {
            return { path: '/home'}
        },
        children:[
            {
                path:'home',
                name:'Home',
                component:Home,
            },
            {
                path:'find-care',
                name:'FindCare',
                component:FindCare,
            },
            {
                path:'find-care/search',
                name:'FindCareSearch',
                component:FindCareSearch,
            },
            {
                path:'find-care/search/filters',
                name:'FindCareSearchFilters',
                component:FindCareSearchFilters,
            },
            // {
            //     path:'marketplace',
            //     name:'marketplace',
            //     component:Marketplace,
            // },
            // {
            //     path:'marketplace/solutionCategory',
            //     name:'solutionCategoryMarketplace',
            //     component:SingleCategoryMarketplace,
            // },
            // {
            //     path:'marketplace/filters',
            //     name:'marketplaceFilters',
            //     component:MarketplaceFilters,
            // },
            // {
            //     path:'marketplace/solutionDetails',
            //     name:'marketplaceSolutionDetail',
            //     component:MarketplaceSolutionDetail,
            // },

           
            {
                path:'contact-us',
                name:'contactUsIndex',
                component:ContactUsIndex,
                redirect: () => {
                    return { path: '/contact-us/index'}
                },
                children:[
                    {
                        path:'index',
                        name:'contactUs',
                        component:ContactUs,
                    },
                ]
            },
            {
                path:'auth',
                name:'authIndex',
                component:AuthIndex,
                redirect: () => {
                    return { path: '/auth/index'}
                },
                children:[
                    {
                        path:'index',
                        name:'Entry',
                        component:Entry,
                    },
                    // {
                    //     path:'register',
                    //     name:'MemberRegister',
                    //     component:Register,
                    // },
                    {
                        path:'alert/:type',
                        name:'Alert',
                        component:Alert,
                    },
                    {
                        path:'confirm-email-password',
                        name:'ConfirmEmail',
                        component:ConfirmEmail,
                    },
                    {
                        path:'change-password',
                        name:'MemberChangePassword',
                        component:MemberChangePassword,
                    },
                    {
                        path:'personal-info',
                        name:'MemberPersonalInfo',
                        component:MemberPersonalInfo,
                    },
                    {
                        path:'confirm',
                        name:'AccountConfirmed',
                        component:AccountConfirmed,
                    },
                    // {
                    //     path:'welcome',
                    //     name:'WelcomePage',
                    //     component:WelcomePage,
                    // },
                ]
            },
       
        ]
    },

]

export default router;