import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import vuetify from './plugins/vuetify'
import css from './index.scss'
import VueCookies from 'vue-cookies'
// import VueGtag from 'vue-gtag'
// import VueMatomo from 'vue-matomo';
import Rollbar from 'rollbar';
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import { VueMaskDirective } from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps';
import vClickOutside from 'v-click-outside'

// Mixpanel Integration
import Mixpanel from 'mixpanel-browser';

// Mixpanel.init(''); 
Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);



Vue.directive('mask', VueMaskDirective);
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(require('vue-moment'));


Vue.use(VueCookies)
Vue.use(VueSweetalert2);
Vue.filter("formatDate", function(value){
  // value.split("T")[0]
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
} );
// Google Maps
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY, // Replace with your API key
    libraries: 'places', // You can add more libraries as needed
    callback: 'initMap', // Specify the callback function name
    async: true, // This is the default, but can be explicitly stated
  },
  installComponents: true,
});
// Vue.use(VueMatomo, {
//   host: 'https://apaly.matomo.cloud/',
//   siteId: 1,
//   trackerFileName: 'matomo',
//   router:router,
// });
// Vue.use(VueGtag, {
//   config: {
//     id: "GTM-T6G2DVJX",  
//     // id: "G-RYD1MZ66EC", // dev
//   }
// });
Vue.prototype.$rollbar = new Rollbar({
  accessToken: '8dc0f5bc86ba4b06a55be47b0dfd9fdf',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    // Track your events to a specific version of code for better visibility into version health
    code_version: '1.0.0',
    // Add custom data to your events by adding custom key/value pairs like the one below
    custom_data: 'foo'
  },
  checkIgnore: function(isUncaught, args, payload) { return payload.request && payload.request.url && payload.request.url.startsWith('http://localhost'); }
});
Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};
Vue.config.productionTip = false;

// We are no longer using this
// We no longer use &role to any components to know if user is logged in or not
// I don't know when the project began to not use $role
// if(localStorage.getItem('user')){
//   let user = JSON.parse(localStorage.getItem('user'));
//   Vue.prototype.$role = user.role;
// }else{
//   Vue.prototype.$role = "no-login"
// }


new Vue({
  router,
  store,
  vuetify,
  css,
  VueCookies,
  Mixpanel,
  vClickOutside,
  render: h => h(App)
}).$mount('#app')
