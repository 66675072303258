<template>
  <div class="set_password_layout">
    <!-- CONTENT -->
    <div class="register-page-container">
      <div class="register-page-form">
        <!-- FORM SCROLLING -->
        <!-- <div class="form_scorlling_add_family"> -->

        <!-- HEADER -->
        <div class="sign_header_container">
          <div class="sign_header">
            <!-- BACK BUTTON -->
            <div class="sign_header_back">
              <img
                src="/svg/backArrow.svg"
                role="button"
                @click="$router.go(-1)"
              /><br />
            </div>

            <!-- LOGO -->
            <div class="sign_header_logo">
              <img src="/apaly-logo.png" />
            </div>
          </div>
        </div>

        <!-- FORM SCROLLING -->
        <div class="form_scorlling_add_family">
          <!-- FORM -->
          <div class="form_desk_up" id="scrollUpForm">
            <v-form
              v-model.trim="validInfo"
              ref="confirmInfo"
              class="form_cutom_width mb-10"
            >
              <div class="font-36 font-weight-500 mb-0">Edit</div>
              <br />
              <div class="font-20 font-weight-500 pb-0 mb-0">
                Contact Information
              </div>
              <br />
              <div class="pt-0">
                <label>Mobile Phone Number</label>
                <v-text-field
                  class="round-input"
                  v-model.trim="formattedPhoneNumber"
                  :rules="phoneRules"
                  @input="formatPhoneNumber"
                  placeholder="(999) 999-9999"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div class="pt-0">
                <strong> Note:</strong> To change your email, go to
                <a @click="$router.go(-1)">Settings</a>
              </div>

              <div class="d-flex mt-7">
                <!-- MEM 450: Canceling should direct users to Home -->
                <v-btn
                  class="col-4 modal_radius_btn no_box_shadow form_btn"
                  color="#0069F3"
                  text
                  @click="$router.go(-1)"
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="dialog_double_button text-white dialog_btn_floatRight"
                  :disabled="!validInfo"
                  color="#0069F3"
                  @click.prevent="editContactInformation()"
                >
                  Save Information
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>

        <!-- </div> -->
      </div>
    </div>

    <!-- ALL SET -->
    <v-dialog v-model="success" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="goAccountSettings()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">Great! You're all set.</div>
          <div class="dialog_subtext dialog_second">
            Contact Information confirmed
          </div>
          <div class="dialog_buttons">
            <button
              class="dialog_single_button dialog_btn_primary"
              @click="goAccountSettings()"
            >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "@/services/api";
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: {
    dependentModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      // subscriber: {},
      validInfo: false,
      success: false,
      formattedPhoneNumber: "",
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  components: {},

  computed: {
    ...mapState({
      subscriber: (state) => state.memberInfo,
    }),
  },
  watch: {},
  mounted() {},
  created() {
    if (this.$route.query.id) {
      // this.formattedPhoneNumber = ''
      this.getDependentPhone(this.$route.query.id);
    } else {
      this.formattedPhoneNumber = this.subscriber.phone;
    }
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Edit Contact Information", // Optional
    //   });
    // } else {
    //   // console.error("Matomo object or trackPageView method is not defined.");
    // }
  },
  methods: {
    getDependentPhone(id) {
      const dependent = this.subscriber.family.find(
        (member) => member.id === id
      );
      if (dependent) {
        api()
          .get(`/member/app/family/member/${dependent.id}`)
          .then((res) => {
            if (res && res.data) {
              this.formattedPhoneNumber = res.data.phone ? res.data.phone : "";
            }
          })
          .catch((err) => {});
      } else {
        this.formattedPhoneNumber = "";
      }
    },
    editContactInformation() {},
    goAccountSettings() {
      this.$router.push("/account/details");
    },
    scrollUpForm() {
      document.getElementById("scrollUpForm").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    },
    formatPhoneNumber(event) {
      this.formattedPhoneNumber = this.formatAsPhoneNumber(event);
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value =
        value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
  },
};
</script>

<style scoped lang="scss">
.register-page-form {
  // border: 1px solid red;
}
.register-page-container {
  width: 100%;
}
.register-page {
  // border: 1px solid red;
  padding: 20px;
  margin-top: 0px;
  height: 100vh;
  // border: 1px solid red;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.hide-desktop {
  display: none !important;
}
.round-input {
  border-radius: 8px;
}
label {
  color: #333333 !important;
  font-weight: 400;
}
.family-page {
  padding: 64px;
  margin-top: 5vh;
}
.apaly-logo {
  width: 130px;
}
.family-page-container {
  margin: 0 auto;
}
.family-page-logo {
  left: 30px;
}
.family-page-title {
  text-align: center;
}
.family-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}

.family-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.family-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.family-page-right-side-form {
  width: 50%;
  align-self: center;
  padding-left: 20px;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
@media only screen and (max-width: 600px) {
  .apaly-logo {
    height: 40px;
    width: 70px;
  }
  .hide-desktop {
    display: flex !important;
  }
  .hide-mobile {
    display: none !important;
  }
  .router-view {
    padding-top: 0px !important;
  }
  .family-page-logo {
    display: flex;
    justify-content: space-between;
  }
  .family-page {
    padding: 24px;
    margin-top: 0px;
  }
  .family-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .family-page-container {
    width: 100%;
  }
  .family-page-footer {
    padding: 0;
    margin-top: 20px;
  }

  .family-page-right-side-form {
    width: 100%;
    padding: 0;
  }
  .family-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
