<template>
 <div> 
    <FullscreenLoader v-if="screenLoader" />

  <!-- <div  v-if="skeleton" class="mt-5">
     <v-skeleton-loader  width="400"  type="list-item"></v-skeleton-loader>
  <v-skeleton-loader  width="400"  type="list-item"></v-skeleton-loader>
  <v-skeleton-loader width="400" type="list-item"></v-skeleton-loader>
</div> -->
 
  <div  class="mt-10 mt-md-0">
    <div class="col-12 justify-space-between d-md-flex">
      <div class="col-12 col-md-4 col-lg-4 col-xl-4">
        <!-- Personal -->
        <div
          class="col-12 d-flex pa-0"
          @click="openPannel('personalInfo')"
          role="button"
          :style="
            pannelName == 'personalInfo' ? 'background-color:#F2F2F2' : ''
          "
        >
          <div class="col-md-10 col-11 pl-0 pr-0">
            <div class="col-12 pa-0 font-weight-500 font-20">Personal</div>
            <div class="col-12 pa-0 font-weight-400 font-16">
              Personal, contact and insurance info
            </div>
            <div class="small_label_red" v-if="user.nextStep == 'confirmPlan'">
              *pending eligibility confirmation
            </div>
            <!-- <div class="small_label_red" v-if="user.nextStep == 'selectDPC'">
              *no provider was selected
            </div> -->
          </div>
          <div class="col-1 mt-2 pl-1">
            <img
              style="width: 30px; height: auto"
              :src="`/svg/chevron_right.svg`"
            />
          </div>
        </div>
        <v-divider class="mt-1" horizontal></v-divider>
        <!-- Family -->
        <div
          class="col-12 d-flex pa-0"
          @click="openPannel('familyMembers')"
          role="button"
          :style="
            pannelName == 'familyMembers' ? 'background-color:#F2F2F2' : ''
          "
        >
        <div class="col-md-10 col-11 pl-0 pr-0">
            <div class="col-12 pa-0 font-weight-500 font-20">
              Family Members
            </div>
            <div class="col-12 pa-0 font-weight-400 font-16">
              Manage family members
            </div>
          </div>
          <div class="col-1 mt-2 pl-1">
            <img
              style="width: 30px; height: auto"
              :src="`/svg/chevron_right.svg`"
            />
          </div>
        </div>
        <v-divider class="mt-1" horizontal></v-divider>
        <!-- Settings -->
        <div
          class="col-12 d-flex pa-0"
          @click="openPannel('settings')"
          role="button"
          :style="pannelName == 'settings' ? 'background-color:#F2F2F2' : ''"
        >
        <div class="col-md-10 col-11 pl-0 pr-0">
            <div class="col-12 pa-0 font-weight-500 font-20">Settings</div>
            <div class="col-12 pa-0 font-weight-400 font-16">
              Login, password, notifications
            </div>
          </div>

          <div class="col-1 mt-2 pl-1">
            <img
              style="width: 30px; height: auto"
              :src="`/svg/chevron_right.svg`"
            />
          </div>
        </div>
        <v-divider class="mt-1" horizontal></v-divider>
      </div>

      <!-- Buttons -->
      <div class="tab_side_container" v-if="pannelName == 'familyMembers'" >
        <!-- Spacer??? -->
        <div class="hideOnMoblie" :style="`height: ${user.family && user.family.length !== 0 ? '90px' : '0px'}`"></div>

        <!-- Family buttons and list -->
        <div :class="user.family && user.family.length !== 0 ? 'tab_side_filled' : 'tab_side_empty' ">
          <div class="mt-2 pa-0side_btns ">
            <!-- FAMILY MEMBER LIST -->
            <div
              v-for="(member, index) in user.family"
              :key="index"
              class="tab_side_list"
              role="button"
              :style="member ? 'background-color:#F2F2F2' : ''"
            >
              <div
                class="col-10 cursor-pointer"
                @click="familyMemberDetails(member)"
              >
                <div class="col-12 pa-0" role="button">
                  {{ member.firstName }} {{ member.lastName }}
                </div>
              </div>

              <div @click="familyMemberDetails(member)" class="col-1 mr-1">
                <img style="height: auto" :src="`/svg/chevron_right.svg`" />
              </div>
            </div>

            <!-- ADD FAMILY BUTTON -->
            <v-btn
              @click="openAddNewMember()"
              color="#194680"
              small
              style="color: white"
              class="addFamily_btn"
            >
              Add a family member
              <v-icon size="small">mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="col-12 d-flex pa-0"></div>
      </div>

      <!-- PERSONAL DETAILS -->
      <div
        class="col-12 col-md-6 col-xl-6 col-lg-6 pr-md-12"
        v-if="pannelName == 'personalInfo'"
      >
        <div>
          <v-card height="auto" class="pa-3 pb-0 mb-1">
            <div class="col-12 pb-0 font-16 font-weight-400">
              Personal Information
            </div>

            <div class="d-flex col-md-6 col-12">
              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">
                  First Name
                </div>

                <div class="col-12 pa-0 font-14">{{ user.firstName }}</div>
              </div>

              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">Last Name</div>

                <div class="col-12 pa-0 font-14">{{ user.lastName }}</div>
              </div>
            </div>

            <div class="d-flex col-md-6 col-12">
              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">
                  Date of Birth
                </div>

                <div class="col-12 pa-0 font-14">
                  <!-- {{
                    user.dob
                      ? new Date(user.dob).toLocaleDateString("en-GB")
                      : "/"
                  }} -->
                  {{
                     dobFormat(user.dob)
                  }}
                </div>
              </div>

              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">Gender</div>

                <div class="col-12 pa-0 font-14">
                  {{
                    user.gender ? (user.gender == "f" ? "Female" : "Male") : "/"
                  }}
                </div>
              </div>
            </div>
            <div class="col-12 pb-0 font-16 font-weight-400">
              Insurance Information
            </div>

            <div class="d-flex col-md-6 col-12">
              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">Member ID</div>

                <div class="col-12 pa-0 font-14">{{ user.memberId }}</div>
              </div>

              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">Plan</div>

                <div class="col-12 pa-0 font-14">{{ user.planId }}</div>
              </div>
            </div>

            <div class="d-flex col-md-6 col-12">
              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">Payer ID</div>

                <div class="col-12 pa-0 font-14">{{ user?.payerId }}</div>
              </div>

              <div class="col-12 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">
                  Plan eligibility status
                </div>

                <!-- {{ user }} -->

                <!-- <div v-if="user.eligibility && user.eligibility.status" class="col-12 pa-0 font-14">
                  {{ user.eligibility.status.charAt(0).toUpperCase() + user.eligibility.status.slice(1) }}
                </div> -->

                <div class="col-12 pa-0 font-14" style="float:right;display:flex;align-items:center;padding:12px;padding-left:0;">
                  <!-- <div class="status_dot" :style="`color:${statusColor(user.subscription.status)};`">&bullet;</div> &nbsp;
                  {{ statusText(user.subscription) }} -->
                  <div class="status_dot" :style="`color:${statusColor(user?.eligibility?.status)};`">&bullet;</div> &nbsp;
                  {{ statusText(user?.eligibility) }}
                </div>

                <br>

                <div v-if="user.nextStep == 'confirmPlan'">
                  <!-- Show this button if failed the eligibility test before -->
                  <div class="col-12" style="padding-left: 0;">
                    <v-btn
                      @click="reconfirmEligibility()"
                      color="#194680"
                      small
                      style="color: white"
                    >
                      Confirm Eligibility
                    </v-btn>
                  </div>
                </div>

                <div v-else-if="user.nextStep == 'selectDPC'" >
                  <!-- Show this button if failed the eligibility test before -->
                  <div class="col-12" style="padding-left: 0;">
                    <v-btn
                      @click="selectProvider(user)"
                      color="#194680"
                      small
                      style="color: white"
                    >
                      Select Provider
                    </v-btn>
                  </div>
                </div>

              </div>
            </div>
            <div class="d-flex col-12">
              <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">Employer</div>

                <div class="col-12 pa-0 font-14">{{ user.companyName }}</div>
              </div>
            </div>
            <div class="d-flex col-12">
              <div class="col-12 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">
                  Relationship to Subscriber
                </div>

                <div class="col-12 pa-0 font-14">
                  {{ user.subscriberRelation }}
                </div>
              </div>
            </div>
            <div class="d-flex col-12">
              <!-- Remove for now -->
              <!-- <div class="col-6 pa-0">
                <div class="col-12 pa-0 font-14 font-weight-600">
                  <v-btn
                    @click="$router.push('/account/edit-subscriber')"
                    color="#194680"
                    outlined
                    small
                    style="border-radius: 8px"
                  >
                    Update Info
                  </v-btn>
                </div>
              </div> -->
            </div>
          </v-card>

          <v-card height="auto" class="pa-3 mb-3">
            <div
              class="col-12 font-16 font-weight-400 d-flex pb-0 justify-space-between"
            >
              Contact Information
              <!-- <img
                @click="$router.push('/account/edit-contact')"
                role="button"
                src="/svg/blue-edit.svg"
              /> -->
            </div>

            <div class="col-12 pb-0">
              <div class="col-12 pa-0 font-14 font-weight-600">Email</div>

              <div class="col-12 pa-0 font-14">{{ user.email }}</div>
            </div>

            <div class="col-12">
              <div class="col-12 pa-0 font-14 font-weight-600">
                Mobile Phone
              </div>

              <div class="col-12 pa-0 font-14">
                {{ user.phone ? user.phone : "/" }}
              </div>
            </div>
          </v-card>

          <!-- <v-card height="200" class="pa-3 mb-3" v-if="user.provider && user.location"> -->
          <v-card height="auto" class="pa-3 mb-3">
            <!-- <div class="font-16 font-weight-400">Provider</div> -->
            <div class="col-12 font-16 font-weight-400">
              My Advanced Primary Care
            </div>
            <div class="home_card_provider" v-if="user?.location && user.nextStep !== 'complete'">
                <img 
                    class="home_card_provider_img" 
                    :src=" user?.location.logo ? user?.location.logo : '/tpa/clinicsAndOrganizations.svg'" 
                />
                <div class="home-card_provider_details">
                    <div class="home_card_provider_name">{{ user?.location.name }}</div>
                    <div class="home_card_provider_icontext">
                    <div class="home_card_provider_icon"><img src="/svg/cardLocation.svg" /></div>
                    <div class="home_card_provider_text">
                        {{ user?.location?.address1 }},&nbsp;{{ user?.location.state }},&nbsp;{{ user?.location.zip }}
                    </div>
                    </div>
                </div>
            </div>

            <div v-if="user.nextStep == 'complete'">
              <v-card
                v-if="user.location"
                height="auto"
                style="width: calc(100% - 15px)"
                class="card-radius v_card_height no_box_shadow map_pin_window"
              >
                <!-- <div class="marketplace-item tester"> -->
                <div class="no_box_shadow">
                  <!-- CARD HEADER -->
                  <div class="market_card_header_map">
                    <!-- LOGO -->
                    <div
                      class="card_logo_details"
                      :style="`background-image:url(${returnCardImage(
                        user.location
                      )})`"
                    ></div>

                    &emsp;
                    <div class="marketplace-item-content-name pt-1">
                      {{ user.location.name }}<br />

                      <p class="ma-0 d-flex card_text_map">
                        <span class="text-ellipsis">
                          <img
                            class="mr-0 card_icon_map"
                            src="/svg/cardLocation.svg"
                          />
                          {{ user.location.address1 }}&nbsp;{{
                            user.location.city
                          }},&nbsp;{{ user.location.state }}&nbsp;{{
                            user.location.zip
                          }}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div class="card_info_container_map pt-1 pb-1">
                    <!-- BUTTONS -->
                    <div class="text-start">
                      <v-btn
                        color="#194680"
                        small
                        outlined
                        style="border-radius: 8px"
                        v-if="
                          user.nextStep == 'complete' && user.providerPortal
                        "
                        @click="openCustomLink(user.providerPortal)"
                      >
                        Go to provider member portal
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-12 pb-1">
                    <!-- BUTTONS CHANGE PROVIDER-->
                    <div
                      class="font-14 text-start d-flex"
                      style="color: #194680"
                    >
                      <!-- <p class="mb-0">Change my provider*</p>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            class="pl-2"
                            src="/svg/blue-info.svg"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span style="border-radius: 8px"
                          >You can only change provider once a month.
                          <br /><br />
                          If you already had a visit with your provider this
                          month,<br />
                          you will need to wait until the 1st of the next month
                          to change providers.</span
                        >
                      </v-tooltip> -->
                    </div>
                  </div>
                </div>
              </v-card>
              <div v-if="user.eligibility?.status == 'pending' || user.subscription?.status == 'pending' || user.subscription?.status == 'active'">
              <div class="col-12">
                <v-btn
                  @click="selectProvider(user)"
                  color="#194680"
                  small
                  style="color: white; float: right; margin-top: -25px;"
                >
                    Update My Provider
                </v-btn>
              </div>
            </div>
            </div>
            <div v-else-if="user.nextStep == 'selectDPC'">
              <!-- Show this button if failed the eligibility test before -->
              <div class="col-12">
                <v-btn
                  @click="selectProvider(user)"
                  color="#194680"
                  small
                  style="color: white"
                >
                  Select Provider
                </v-btn>
              </div>
            </div>
            <div v-else-if="user.eligibility?.status == 'pending' || user.subscription?.status == 'pending' || user.subscription?.status == 'active'">
              <div class="col-12">
                <v-btn
                  @click="selectProvider(user)"
                  color="#194680"
                  small
                  style="color: white; float: right; margin-top: -25px;"
                >
                    Update My Provider
                </v-btn>
              </div>
            </div>

          </v-card>
          <v-card height="auto" class="pa-3 mb-3">
            <div class="col-12">
              <div
                class="col-12 pa-0 font-14 font-weight-400 d-flex justify-space-between"
              >
                <span>Membership Status</span>
                <span v-if="user.subscription && user.subscription.status">
                  <div class="col-12 pa-0 font-14" style="float:right;display:flex;align-items:center;padding:12px;padding-left:0;">
                    <div class="status_dot" :style="`color:${statusColor(user?.subscription?.status)};`">&bullet;</div> &nbsp;
                    <div style="white-space:nowrap;">{{ statusText(user?.subscription) }}</div>
                  </div>
                </span>
              </div>
            </div>
            <div
              v-if="user.subscription"
              class="col-12 pt-0"
            >
              <div
                class="col-12 pa-0 font-14 font-weight-400 d-flex justify-space-between"
              >
                <span>Membership Valid Until</span>
                <span v-if=" user.subscription.expire" class="font-weight-600">
                  {{ user.subscription?.expire ? returnFormattedDate(user.subscription?.expire) : '-' }}
                </span>
                <span v-else class="font-weight-600">-</span>
              </div>
            </div>
          </v-card>
        </div>
      </div>

      <!-- MEMBER DETAILS -->
      <!-- <div class="col-12 col-md-6 col-xl-6 col-lg-6 d-flex" v-if="pannelName == 'familyMembers' && familyDetails.nextStep != 'selectDPC'" > -->
      <div
        class="col-12 col-md-6 col-xl-6 col-lg-6 d-flex"
        v-if="pannelName == 'familyMembers'"
      >
        <div class="col-12" v-if="memberInfo">
          <div class="col-12" v-if="memberInfoLoader">
            <v-progress-circular
              indeterminate
              :size="40"
              color="primary"
              class="text-center mt-5 mb-5"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-card height="auto" class="pa-3 mb-3">
              <div class="col-12 font-16 font-weight-400">
                Personal Information
              </div>

              <div class="d-flex col-md-6 col-12">
                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    First Name
                  </div>

                  <div class="col-12 pa-0 font-14">
                    {{ familyDetails.firstName ? familyDetails.firstName : "" }}
                  </div>
                </div>

                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    Last Name
                  </div>

                  <div class="col-12 pa-0 font-14">
                    {{ familyDetails.lastName ? familyDetails.lastName : "" }}
                  </div>
                </div>
              </div>

              <div class="d-flex col-md-6 col-12">
                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    Date of Birth
                  </div>

                  <div class="col-12 pa-0 font-14">
                    {{ dobFormat(familyDetails.dob) }}
                    <!-- {{
                      familyDetails.dob
                        ? new Date(familyDetails.dob).toLocaleDateString(
                            "en-GB"
                          )
                        : "/"
                    }} -->
                  </div>
                </div>

                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">Gender</div>

                  <div class="col-12 pa-0 font-14">
                    {{
                      familyDetails.gender
                        ? familyDetails.gender == "f"
                          ? "Female"
                          : "Male"
                        : "/"
                    }}
                  </div>
                </div>
              </div>
              <div class="col-12 font-16 font-weight-400">
                Insurance Information
              </div>

              <div class="d-flex col-md-6 col-12">
                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    Member ID
                  </div>

                  <div class="col-12 pa-0 font-14">
                    {{ familyDetails.memberId ? familyDetails.memberId : "" }}
                  </div>
                </div>

                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">Plan</div>

                  <div class="col-12 pa-0 font-14">
                    {{ familyDetails.planId ? familyDetails.planId : "" }}
                  </div>
                </div>
              </div>

              <div class="d-flex col-md-6 col-12">
                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    Payer ID
                  </div>

                  <div class="col-12 pa-0 font-14">
                    {{ familyDetails.payerId ? familyDetails.payerId : "" }}
                  </div>
                </div>

                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    Plan eligibility status
                  </div>

                  <!-- <div v-if=" familyDetails.eligibility && familyDetails.eligibility.status " class="col-12 pa-0 font-14" >
                    {{ familyDetails.eligibility.status.charAt(0).toUpperCase() + familyDetails.eligibility.status.slice(1) }}
                  </div> -->

                  <div class="col-12 pa-0 font-14" style="float:right;display:flex;align-items:center;padding:12px;padding-left:0;">
                    <!-- <div class="status_dot" :style="`color:${statusColor(familyDetails.subscription.status)};`">&bullet;</div> &nbsp;
                    {{ statusText(familyDetails.subscription) }} -->
                    <div class="status_dot" :style="`color:${statusColor(familyDetails?.eligibility?.status)};`">&bullet;</div> &nbsp;
                    {{ statusText(familyDetails?.eligibility) }}
                  </div>

                  <br>

                  <div v-if="familyDetails.nextStep == 'confirmPlan'">
                  <!-- Show this button if failed the eligibility test before -->
                  <div class="col-12" style="padding-left: 0;">
                    <v-btn
                      @click="confirmEligibility(familyDetails)"
                      color="#194680"
                      small
                      style="color: white"
                    >
                      Confirm Eligibility
                    </v-btn>
                  </div>
                </div>

                <div v-else-if="familyDetails.nextStep == 'selectDPC'" >
                  <!-- Show this button if failed the eligibility test before -->
                  <div class="col-12" style="padding-left: 0;">
                    <v-btn
                      @click="selectProvider(familyDetails)"
                      color="#194680"
                      small
                      style="color: white"
                    >
                      Select Provider
                    </v-btn>
                  </div>
                </div>

                

                </div>
              </div>
              <div class="d-flex col-md-6 col-12">
                <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    Employer
                  </div>

                  <div class="col-12 pa-0 font-14">
                    {{
                      familyDetails.companyName ? familyDetails.companyName : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="d-flex col-12">
                <div class="col-12 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    Relationship to Subscriber
                  </div>

                  <div class="col-12 pa-0 font-14">
                    {{
                      familyDetails.subscriberRelation
                        ? familyDetails.subscriberRelation
                        : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="d-flex col-12">
                <!-- Remobe for now -->
                <!-- <div class="col-6 pa-0">
                  <div class="col-12 pa-0 font-14 font-weight-600">
                    <v-btn
                      @click="
                        $router.push(
                          `/account/edit-dependent/${familyDetails.id}`
                        )
                      "
                      color="#194680"
                      outlined
                      small
                      style="border-radius: 8px"
                    >
                      Update Info
                    </v-btn>
                  </div>
                </div> -->
              </div>
            </v-card>

            <v-card height="auto" class="pa-3 mb-3">
              <div
                class="col-12 font-16 pb-0 font-weight-400 d-flex justify-space-between"
              >
                Contact Information
                <!-- <img
                  role="button"
                  @click="
                    $router.push({
                      path: `/account/edit-contact`,
                      query: { id: familyDetails.id },
                    })
                  "
                  src="/svg/blue-edit.svg"
                /> -->
              </div>

              <div class="col-12 pb-0">
                <div class="col-12 pa-0 font-14 font-weight-600">Email</div>

                <div class="col-12 pa-0 font-14">
                  {{ familyDetails.email ? familyDetails.email : "" }}
                </div>
              </div>

              <div class="col-12">
                <div class="col-12 pa-0 font-14 font-weight-600">
                  Mobile Phone
                </div>

                <div class="col-12 pa-0 font-14">
                  {{ familyDetails.phone ? familyDetails.phone : "" }}
                </div>
              </div>
            </v-card>

            <!-- <v-card height="200" class="pa-3 mb-3" v-if="user.provider && user.location"> -->
            <v-card height="auto" class="pa-3 mb-3">
              <!-- <div class="font-16 font-weight-400">Provider</div> -->
              <div class="col-12 font-16 font-weight-400">
                My Advanced Primary Care
              </div>

              <div v-if="familyDetails.nextStep == 'complete'">
                <v-card
                  v-if="familyDetails.location"
                  height="auto"
                  style="width: calc(100% - 15px)"
                  class="card-radius v_card_height no_box_shadow map_pin_window"
                >
                  <!-- <div class="marketplace-item tester"> -->
                  <div class="no_box_shadow">
                    <!-- CARD HEADER -->
                    <div class="market_card_header_map">
                      <!-- LOGO -->
                      <div
                        class="card_logo_details"
                        :style="`background-image:url(${returnCardImage(
                          familyDetails.location
                        )})`"
                      ></div>

                      &emsp;
                      <div class="marketplace-item-content-name pt-1">
                        {{ familyDetails.location.name }}<br />

                        <p class="ma-0 d-flex card_text_map">
                          <span class="text-ellipsis">
                            <img
                              class="mr-0 card_icon_map"
                              src="/svg/cardLocation.svg"
                            />
                            {{ familyDetails.location.address1 }}&nbsp;{{
                              familyDetails.location.city
                            }},&nbsp;{{ familyDetails.location.state }}&nbsp;{{
                              familyDetails.location.zip
                            }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="card_info_container_map pt-1 pb-1">
                      <!-- BUTTONS -->
                      <div class="text-start">
                        <v-btn
                          color="#194680"
                          small
                          outlined
                          style="border-radius: 8px"
                          v-if="
                            familyDetails.nextStep == 'complete' &&
                            familyDetails.providerPortal
                          "
                          @click="openCustomLink(familyDetails.providerPortal)"
                        >
                          Go to provider member portal
                        </v-btn>
                      </div>
                    </div>
                    <div class="col-12 pb-1">
                      <!-- BUTTONS CHANGE PROVIDER-->
                      <div
                        class="font-14 text-start d-flex"
                        style="color: #194680"
                      >
                        <!-- <p class="mb-0">Change my provider*</p>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              class="pl-2"
                              src="/svg/blue-info.svg"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span style="border-radius: 8px"
                            >You can only change provider once a month.
                            <br /><br />
                            If you already had a visit with your provider this
                            month,<br />
                            you will need to wait until the 1st of the next
                            month to change providers.</span
                          >
                        </v-tooltip> -->
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div v-if="familyDetails.eligibility?.status == 'pending' || familyDetails.subscription?.status == 'pending' || familyDetails.subscription?.status == 'active'">
                <div class="col-12">
                    <v-btn
                    @click="selectProvider(familyDetails)"
                    color="#194680"
                    small
                    style="color: white; float: right; margin-top: -25px;"
                    >
                        Update My Provider
                    </v-btn>
                </div>
                </div>

              <div v-else-if="familyDetails.nextStep == 'confirmPlan'">
                <!-- Confirm Eligibility for Dependents/Family Member -->
                <div class="col-12">
                  <v-btn
                    @click="confirmEligibility(familyDetails)"
                    color="#194680"
                    small
                    style="color: white"
                  >
                    Confirm Eligibility
                  </v-btn>
                </div>
              </div>
              <div v-else-if="familyDetails.nextStep == 'selectDPC'">
                <!-- Confirm Eligibility for Dependents/Family Member -->
                <div class="col-12">
                  <v-btn
                    @click="selectProvider(familyDetails)"
                    color="#194680"
                    small
                    style="color: white"
                  >
                    Select Provider
                  </v-btn>
                </div>
              </div>
            </v-card>
            <v-card height="auto" class="pa-3 mb-3">
              <div class="col-12">
                <div
                  class="col-12 pa-0 font-14 font-weight-400 d-flex justify-space-between"
                >
                  <span >Membership Status</span>
                  <span v-if=" familyDetails.subscription && familyDetails.subscription.status " >
                    <div class="col-12 pa-0 font-14" style="float:right;display:flex;align-items:center;padding:12px;padding-left:0;">
                      <div class="status_dot" :style="`color:${statusColor(familyDetails?.subscription?.status)};`">&bullet;</div> &nbsp;
                      <div style="white-space:nowrap;">{{ statusText(familyDetails?.subscription) }}</div>
                    </div>
                  </span>
                </div>
              </div>
              <div
                v-if="user.subscription && user.subscription.expire"
                class="col-12 pt-0"
              >
                <div
                  class="col-12 pa-0 font-14 font-weight-400 d-flex justify-space-between"
                >
                  <span>Membership Valid Until</span>
                  <span class="font-weight-600">
                    {{ user.subscription?.expire ? returnFormattedDate(user.subscription?.expire) : '-' }}
                  </span>
                </div>
              </div>
            </v-card>
          </div>
          <div
            v-if="role == 'Self'"
            @click="memberToRemove(familyDetails)"
            class="col-11"
            role="button"
            align="end"
            style="color: #d90101"
          >
            Delete
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.79844 2.2001L7.95844 1.0801C7.73094 0.777598 7.37594 0.600098 6.99844 0.600098H4.99844C4.62094 0.600098 4.26594 0.777598 4.03844 1.0801L3.19844 2.2001H0.998438C0.665938 2.2001 0.398438 2.4676 0.398438 2.8001V2.8501C0.398438 2.9326 0.465938 3.0001 0.548438 3.0001H1.19844V12.2001C1.19844 12.8626 1.73594 13.4001 2.39844 13.4001H9.59844C10.2609 13.4001 10.7984 12.8626 10.7984 12.2001V3.0001H11.4484C11.5309 3.0001 11.5984 2.9326 11.5984 2.8501V2.8001C11.5984 2.4676 11.3309 2.2001 10.9984 2.2001H8.79844ZM4.99844 1.4001H6.99844C7.12344 1.4001 7.24344 1.4601 7.31844 1.5601L7.79844 2.2001H4.19844L4.67844 1.5601C4.75344 1.4601 4.87344 1.4001 4.99844 1.4001ZM9.99844 12.2001C9.99844 12.4201 9.81844 12.6001 9.59844 12.6001H2.39844C2.17844 12.6001 1.99844 12.4201 1.99844 12.2001V3.0001H9.99844V12.2001ZM5.59844 11.1001V4.5001C5.59844 4.3351 5.73344 4.2001 5.89844 4.2001H6.09844C6.26344 4.2001 6.39844 4.3351 6.39844 4.5001V11.1001C6.39844 11.2651 6.26344 11.4001 6.09844 11.4001H5.89844C5.73344 11.4001 5.59844 11.2651 5.59844 11.1001ZM3.59844 11.1001V4.5001C3.59844 4.3351 3.73344 4.2001 3.89844 4.2001H4.09844C4.26344 4.2001 4.39844 4.3351 4.39844 4.5001V11.1001C4.39844 11.2651 4.26344 11.4001 4.09844 11.4001H3.89844C3.73344 11.4001 3.59844 11.2651 3.59844 11.1001ZM7.59844 11.1001V4.5001C7.59844 4.3351 7.73344 4.2001 7.89844 4.2001H8.09844C8.26344 4.2001 8.39844 4.3351 8.39844 4.5001V11.1001C8.39844 11.2651 8.26344 11.4001 8.09844 11.4001H7.89844C7.73344 11.4001 7.59844 11.2651 7.59844 11.1001Z"
                fill="#D90101"
              />
            </svg>
          </div>
        </div>
      </div>

      <!-- Settings Detials -->
      <div
        class="col-12 col-md-6 col-xl-8 col-lg-8"
        v-if="pannelName == 'settings'"
      >
        <div class="col-12" v-if="loginInfo">
          <div class="font-20 font-weight-500">
            <div class="col-12 pt-0 mb-0">Login</div>
          </div>
          <div class="col-12">
            <label style="color: #333 !important; font-weight: 400"
              >Email</label
            >
            <v-text-field
              placeholder="example@example.com"
              autocomplete="off"
              outlined
              dense
              disabled
              type="email"
              v-model="email"
              style="border-radius: 8px"
            >
              <template v-slot:append>
                <img
                  src="/svg/edit.svg"
                  alt="Employer Icon"
                  class="mt-1"
                  height="17"
                  @click="openEmailInfo()"
                  role="button"
                />
              </template>
            </v-text-field>
          </div>
          <div class="col-12">
            <label style="color: #333 !important; font-weight: 400"
              >Password</label
            >
            <v-text-field
              placeholder="*******************"
              outlined
              autocomplete="off"
              dense
              disabled
              type="password"
              v-model="pass"
              style="border-radius: 8px"
            >
              <template v-slot:append>
                <img
                  src="/svg/edit.svg"
                  alt="Employer Icon"
                  class="mt-1"
                  height="17"
                  @click="openPasswordInfo()"
                  role="button"
                />
              </template>
            </v-text-field>
          </div>
        </div>
        <div class="col-12" v-if="editEmailInfo">
          <div class="font-20 font-weight-500">
            <div class="col-12 pt-0 mb-0">Change Email</div>
          </div>
          <v-form ref="validEmail" v-model.trim="validEmail" class="pa-5 pt-0">
            <div class="col-12">
              <label style="color: #333 !important; font-weight: 400"
                >Email</label
              >
              <v-text-field
                placeholder="Insert New Email"
                outlined
                dense
                type="email"
                v-model="newEmail"
                :rules="emailRules"
                @input="checkEmail()"
                style="border-radius: 8px"
              >
              </v-text-field>
            </div>
            <div class="col-12">
              <v-btn
                class="col-4 modal_radius_btn no_box_shadow form_btn"
                color="#194680"
                text
                @click="openLoginInfo()"
                >Cancel</v-btn
              >
              <v-btn
                class="col-8"
                :disabled="!validEmail"
                color="#194680"
                style="border-radius: 8px; color: white"
                @click="sendVerificationLink()"
                >Send verification link</v-btn
              >
            </div>
          </v-form>
        </div>
        <div class="col-12 pl-md-12" v-if="resetPass">
          <v-form
            ref="validPassword"
            v-model.trim="validPassword"
            class="pa-md-5 pt-0"
          >
            <div>
              <p v-if="user?.password" class="font-20 pb-0 mb-0 font-weight-500">Reset Password</p>
              <p v-else class="font-20 pb-0 mb-0 font-weight-500">Set Password</p>

              <div class="col-12 pr-0 no-list-style">
                Requirements:
                <ul class="ml-0 pl-0 font-14">
                  <li :class=" isLengthValid ? 'valid-text text-decoration-none' : '' " >
                    <v-icon v-if="isLengthValid" size="small" color="green" >mdi-check</v-icon >
                    <v-icon v-else size="small" style="font-size: 8px">mdi-circle</v-icon>
                    At least 8 characters
                  </li>
                  <li :class="isCapitalLetterValid ? 'valid-text' : ''">
                    <v-icon
                      v-if="isCapitalLetterValid"
                      size="small"
                      color="green"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else size="small" style="font-size: 8px"
                      >mdi-circle</v-icon
                    >
                    At least one upper case letter
                  </li>
                  <li :class="isLowercaseLetterValid ? 'valid-text' : ''">
                    <v-icon
                      v-if="isLowercaseLetterValid"
                      size="small"
                      color="green"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else size="small" style="font-size: 8px"
                      >mdi-circle</v-icon
                    >
                    At least 1 lowercase letter
                  </li>
                  <li :class="isSpecialCharacterValid ? 'valid-text' : ''">
                    <v-icon
                      v-if="isSpecialCharacterValid"
                      size="small"
                      color="green"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else size="small" style="font-size: 8px"
                      >mdi-circle</v-icon
                    >
                    At least 1 special character - ! @ # $ % etc
                  </li>
                </ul>
              </div>
              <div v-if="user?.password" class="col-12 pl-0 pr-0 pb-0">
                <label for="" class="label-text">Current Password</label>
                <v-text-field
                  :key="pwKey"
                  ref="passInput"
                  outlined
                  v-model.trim="current_password"
                  required
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  placeholder="Insert current password"
                  @blur="validatePassword()"
                  :rules="currentPasswordRules"
                  style="border-radius: 8px"
                ></v-text-field>
              </div>
              <div class="col-12 pl-0 pr-0 pb-0">
                <label class="label-text">{{ user?.password ? 'New' : '' }} Password</label>
                <v-text-field
                  outlined
                  v-model.trim="password"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  placeholder="New password"
                  :rules="passRules"
                  style="border-radius: 8px"
                  @input="confirmPass()"
                ></v-text-field>
              </div>

              <div class="col-12 mb-0 pl-0 pr-0 pb-0">
                <label for="" class="label-text"> Confirm {{ user?.password ? 'New' : '' }} Password</label>
                <v-text-field
                  outlined
                  v-model.trim="confirmpassword"
                  required
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  placeholder="Confirm new password"
                  :rules="confirmPassRules"
                  style="border-radius: 8px"
                  @input="confirmPass()"
                ></v-text-field>
              </div>
              <v-col v-if="error.length > 0" class="mt-3 mb-3 col-md-12">
                <v-alert dense outlined type="error">
                  {{ error }}
                </v-alert>
              </v-col>
              <div class="col-12 pl-0 pr-0">
                <v-btn
                  class="col-4 modal_radius_btn no_box_shadow form_btn"
                  color="#194680"
                  text
                  @click="cancelNewPass()"
                  >Cancel</v-btn
                >
                <v-btn
                  class="col-8"
                  color="#194680"
                  :disabled="!validPassword"
                  style="border-radius: 8px; color: white"
                  @click="resetPassword()"
                >
                  Reset Password
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </div>

    <!-- REMOVE DEPENDENT WARNING -->
    <v-dialog
      v-model.trim="removeMemberDialog"
      width="600"
      class="success-modal"
    >
      <v-card>
        <div role="button" align="end" class="col-12 justify-end text-end pa-2">
          <v-icon @click="removeMemberDialog = false">mdi-close</v-icon>
        </div>
        <v-card-text class="pt-5">
          <div class="text-center font-24" style="color: #d90101">
            Are you sure you want to remove this family member from your
            account?
          </div>
          <div>
            <div class="col-12 pl-5 font-16 mt-2 text-gray">
              This action cannot be undone.
            </div>
          </div>
          <div class="d-flex justify-center mt-2">
            <v-btn
              @click="removeMember()"
              color="#D90101"
              class="submit-btn mr-3"
              style="color: white"
            >
              Remove
            </v-btn>
            <v-btn
              @click="removeMemberDialog = false"
              color="#D90101"
              outlined
              class="submit-btn"
            >
              No, go back
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ERROR FROM BE -->
    <v-dialog v-model.trim="messages" width="600" class="success-modal">
      <v-card>
        <div role="button" align="end" class="col-12 justify-end text-end pa-2">
          <v-icon @click="okayBtn()">mdi-close</v-icon>
        </div>
        <v-card-text class="pt-5" v-if="errorMsg">
          <div class="text-center font-24" style="color: #d90101">
            {{ errorMsg }}
          </div>
          <div></div>
          <div class="d-flex justify-center mt-2">
            <v-btn
              @click="okayBtn()"
              color="#D90101"
              class="submit-btn mr-3"
              style="color: white"
            >
              Okay
            </v-btn>
          </div>
        </v-card-text>

        <v-card-text class="pt-5" v-if="removedMsg">
          <div class="text-center font-24 mb-5" style="">
            {{ removedMsg }}
          </div>
          <div></div>

          <div class="d-flex justify-center mt-2">
            <v-btn
              @click="okayBtn()"
              color="#194680"
              class="submit-btn mr-3"
              style="color: white"
            >
              Okay
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CONFIRMING INSURANCE -->
    <v-dialog v-model="checkingEligibility" width="350px">
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="checkingEligibility = false"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center mt-3">
          <v-progress-circular
            indeterminate
            :size="50"
            color="primary"
            class="text-center mt-5 mb-5"
          ></v-progress-circular>
          <div class="font-24 font-weight-400 mb-5">
            We are confirming your dependent's insurance information
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model.trim="linkSentModal" width="510" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="goToLogin()">
              <v-icon>mdi-close</v-icon>
            </p>
          </span>
          <div class="display">
            <div class="text-center">
              <img src="/svg/email_sent.svg" />
            </div>
            <div style="font-size: 24px; color: #333333; margin-top: 20px">
              Check your E-mail
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              We sent an email to <strong>{{ newEmail }}</strong>
            </div>
          </div>

          <div class="text-center text--white mt-6">
            <v-btn
              color="#194680"
              class="email-sent text-white"
              width="170"
              @click="goToLogin()"
            >
              OK!
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model.trim="passwordSaved"
      width="500"
      class="success-modal"
      persistent
    >
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="justify-content-end text-end col-12 pa-0">
            <v-icon @click="goToLogin()">mdi-close</v-icon>
          </div>

          <div class="display mt-1">
            <div
              class="text-start font-24 mt-2 font-weight-400 mt-0 mb-2"
              style="color: #014d00"
            >
              Password saved successfully!
            </div>
            <br>
            <div class="text-start font-16 mt-3 font-weight-300 mb-5">
              Please continue to login.
            </div>
            <div class="text-center text--white mt-6">
              <v-btn
                color="#194680"
                class="done-btn text-white"
                @click="goToLogin()"
              >
                Continue to Login
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model.trim="emailAlreadyExists"
      width="500"
      class="success-modal"
      persistent
    >
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="justify-content-end text-end col-12 pa-0">
            <v-icon @click="closeEmailExists()">mdi-close</v-icon>
          </div>

          <div class="display mt-1">
            <div
              class="text-start font-24 mt-2 font-weight-400 mt-0 mb-2"
              style="color: #d90101"
            >
              This e-mail is already registered.
            </div>
            <div class="text-center text--white mt-6">
              <v-btn
                color="#194680"
                class="done-btn text-white"
                @click="closeEmailExists()"
              >
                Try another email
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="rightPass" width="500" class="success-modal" persistent>
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="justify-content-end text-end col-12 pa-0"> <v-icon @click="goToLogin()">mdi-close</v-icon></div>
          <div class="display mt-1">
            <span class="text-start font-24 mt-2 font-weight-400 mt-0 mb-0" style="color:#014D00">
              Password saved successfully!
            </span>
            <br>
            <span class="text-start font-16 mt-2 font-weight-300  mb-5">
              Please continue to login.
            </span>
            <div class="text-center text--white mt-6">
              <v-btn color="#194680" class="done-btn text-white" @click="goToLogin()">
                Continue to Login
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
        <!-- You have an pending/active membership -->
        <v-dialog v-model="cannotEnrollModal" width="500px" persistent>
            <v-card class="dialog_layout">
                <v-card-title class="d-flex justify-end">
                <v-icon role="button" @click="cannotEnrollModal = false" v-text="'mdi-close'" slot="end"></v-icon>
                </v-card-title>

                <v-card-text class="dialog_content">
                <div class="dialog_title dialog_nb">
                    You have an active membership with <span class="dialog_blue">{{ user?.location?.name }}</span>
                </div>
                <div class="dialog_subtext dialog_second mt-5">
                    Please contact us through our support email at
                    <strong class="dialog_blue">support@apaly.com</strong> for further assistance.
                </div>
                <div class="dialog_buttons">
                    <button class="dialog_single_button dialog_btn_primary" @click="cannotEnrollModal = false">
                    Got It
                    </button>
                </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    <errorModal
      v-if="somethingWrongModal"
      :someThingWentWrong="somethingWrongModal"
      :errorMess="erroMessage"
      ref="error_modal"
    ></errorModal>

    <DependentFlow
      v-if="dependentModal"
      @closeDependentModal="dependentModal = false"
      :dependentModal="dependentModal"
    />
  </div> 
 </div>

</template>
<script>
import api from "@/services/api";
import moment from "moment";
import DependentFlow from "../../../Common/DependentFlow.vue";
import errorModal from "../../../Common/SomethingWentWrong.vue";
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';

import { mapState } from "vuex";

export default {
  components: {
    DependentFlow,
    errorModal,
    FullscreenLoader
  },
  data() {
    return {
        screenLoader: false,
        cannotEnrollModal: false,
      rightPass: false,
      // skeleton: true,
      erroMessage: '',
      emailAlreadyExists: false,
      validCurrentPass: false,
      somethingWrongModal: false,
      memberInfoLoader: true,
      dependentProvider: null,
      selectedProvider: null,
      passwordSaved: false,
      linkSentModal: false,
      validPassword: false,
      show: false,
      show1: false,
      show2: false,
      validEmail: false,
      current_password: "",
      resetPass: false,
      editEmailInfo: false,
      email: "",
      pass: null,
      newEmail: null,
      loginInfo: false,
      dependentModal: false,
      checkByProviderID: "",
      postMemberEnrollment: "",
      ifOneMedical: false,
      successEligibility: false,
      confirmingEligibility: false,
      checkingEligibility: false,
      memberSuccessModalNoDPC: false,
      confirmMemberPlan: false,
      confirmFormattedPhoneNumber: "",
      confirmDateFormatted: "",
      confirmMember: {},
      isActive: false,
      memberErrorModal: false,
      memberExists: false,
      successfullyEnrolledDependent: false,
      optionsForDependent: false,
      memberErrorMsg: "",
      calendarMenu: false,
      dateFormatted: "",
      formattedPhoneNumber: "",
      errorMsg: "",
      removedMsg: "",
      successMsg: "",
      memberSuccessModal: false,
      messages: false,
      dependentId: "",
      reviseDependentInfo: false,
      providerId: "",
      locationId: "",
      newMember: {
        dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      member: {
        firstName: "",
        lastName: "",
        dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        gender: "",
        phone: "",
      },
      insuranceInfo: {
        relationshipToSubsrciber: "",
        companyName: "",
        memberId: "",
        planId: "",
        groupId: "",
        payerId: "",
      },
      role: "",
      genders: [
        { text: "Female", value: "f" },
        { text: "Male", value: "m" },
      ],
      relationships: [
        { text: "Spouse", value: "Spouse" },
        { text: "Dependent", value: "Dependent" },
      ],
      eligibilityStep: 1,
      validInfo: false,
      validInsurance: false,
      validEmail: false,
      addMemberModal: false,
      removeMemberDialog: false,
      personalInfo: false,
      familyMembers: false,
      settings: false,
      memberInfo: false,
      memberError: false,
      confirmMemberErrorModal: false,
      confirmMemberSuccessModal: false,
      pannelName: "",
      authMember: {},
      // user: {},
      family: [],
      familyDetails: {},
      plans: [],
      removeItem: {},
      password: "",
      confirmpassword: "",
      pwKey: "init",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) =>
          (v && v.length > 8) ||
          "Password does not meet the security requirements",
        (v) =>
          (v && /[A-Z]{1}/.test(v)) ||
          "Password does not meet the security requirements",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "Password does not meet the security requirements",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) ||
          "Password does not meet the security requirements",
        (v) =>
          v != this.current_password ||
          "For security reasons, your new password cannot be the same as your old one.",
      ],
      confirmPassRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) =>
          (v && v.length > 8) ||
          "Password does not meet the security requirements",
        (v) =>
          (v && /[A-Z]{1}/.test(v)) ||
          "Password does not meet the security requirements",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "Password does not meet the security requirements",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) ||
          "Password does not meet the security requirements",
        (v) => v == this.password || "Password must match",
        (v) =>
          v != this.current_password ||
          "For security reasons, your new password cannot be the same as your old one.",
      ],
      currentPasswordRules: [
        (v) => !!v || "This field is required",
        () => this.validCurrentPass || "Invalid password",
      ],
      passsError: "",
      error: "",
      requiredRules: [(v) => !!v || "This field is required"],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      IdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{3}\-[0-9]{6}/.test(v) ||
          "ID should contain 9 digits, Please enter your ID in XXX-XXXXXX format",
      ],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      confirmSemafor: true,
      addMemberSemafor: true,
      confirmMemberSemafor: true,
    };
  },
  computed: {
    isLengthValid() {
      return this.password.length >= 8;
    },
    isCapitalLetterValid() {
      return /[A-Z]/.test(this.password);
    },
    isLowercaseLetterValid() {
      return /[a-z]/.test(this.password);
    },
    isSpecialCharacterValid() {
      return /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    },

    ...mapState({
      user: (state) => state.memberInfo,
    }),
  },
  watch: {
    "newMember.dob"(val) {
      this.dateFormatted = moment.utc(this.newMember.dob).format("MM/DD/YYYY");
    },
    "confirmMember.dob"(val) {
      this.confirmDateFormatted = moment.utc(this.confirmMember.dob).format(
        "MM/DD/YYYY"
      );
    },
    email() {
      this.email = this.user.email;
    },
  },
  mounted() {
    // Redirect
    localStorage.setItem("redirect", "/account/details");
  },
  async created() {
    this.screenLoader = true;
    await this.$store.dispatch("asyncGetMemberInfo");

    // Clear Cards
    localStorage.removeItem('cards');
    
    // this.getaccountInformation();
    // this.getFamily();
    this.getPlans();
    // this.getHomeInformation();
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Member Account Details",
    //   });
    // } else {
    //   // console.error("Matomo object or trackPageView method is not defined.");
    // }
    if (this.user && this.user.email) {
      this.email = this.user.email;
    }
    // if(this.memberInfo){
    //     this.skeleton = false
    //   }

    this.screenLoader = false;

  },
  methods: {
    returnFormattedDate(date){
      return moment.utc(date).format("MM/DD/YYYY");
    },
    dobFormat(dob) {
      return moment.utc(new Date(dob)).format("MM/DD/YYYY"); // UTC - All TImezones
    },
    statusText(sub) {
      if(sub?.status == "pending") return "Pending first visit"
      else if(sub?.status == "active") return "Active" 
      else if(sub?.status == "inactive") return "Inactive" 
      else return "Eligibility, not confirmed"
    },
    statusColor(status) {
      if(status == "pending") return "#FFD400" // Yellow
      else if(status == "active") return "#04D900" // Green 
      else if(status == "inactive") return "#D90101" // Red 
      else return "#CCCCCC" // Gray
    },
    openCustomLink(providerPortal) {
      if (providerPortal) {
        window.open(providerPortal, "_blank");
      }
    },
    goToLogin() {
      localStorage.clear();
      this.$cookies.remove("token");
      this.$store.commit("setComponentState", false);
      window.location = "/login";
    },
    goToDetails(provider, location) {
      this.$router.push({
        path: `/marketplace/provider/details/${provider}`,
        query: { location: location },
      });
    },
    returnCardImage(card) {
      if (card) {
        if (card.logo) {
          return card.logo;
        } else {
          return "/tpa/clinicsAndOrganizations.svg";
        }
      }
    },
    async validatePassword() {
      let data = {
        password: this.current_password,
      };
      try {
        const res = await api().post(`/member/app/password/validate`, data);
        this.validCurrentPass = res.data.valid;
        if (res.data.valid) {
          this.currentPasswordRules[1] = () => true;
        } else {
          this.currentPasswordRules[1] = () => "Invalid password";
        }
        this.$refs["passInput"].validate();
      } catch (err) {
        this.erroMessage = err.response?.data.message;
        this.somethingWrongModal = true;
      }
    },
    resetPassword() {
      if(this.user?.password){
        let data = {
        password: this.password,
        currentPassword: this.current_password,
      };
      api()
        .post(`/member/app/password/change`, data)
        .catch((err) => {
          if (err) {
            this.erroMessage = err.response?.data.msg;
            this.somethingWrongModal = true;
          }
        })
        .then((res) => {
          if (res) {
            this.passwordSaved = true;
          }
        })
      }
      else{
        this.setPassword()
      }
      
        
    },
    setPassword(){
       api()
              .post(`/member/app/password/reset`, {
                password: this.password,
                token: $cookies.get('token'),
              })
              .catch((err) => {
                if(err.response.status == 403){
               this.somethingWrongModal = true;   
               }
              })
              .then((res) => {
                if(res){
                   this.rightPass = true;
                   this.$store.dispatch('getMemberInfo');
                }
               
              });
    },
    confirmPass() {
      const updatedRules = [...this.confirmPassRules];
      if (this.confirmpassword !== this.password) {
        updatedRules[6] = "Password must match";
      } else {
        updatedRules[6] = true;
      }
      this.confirmPassRules = updatedRules;
      this.somethingWrongModal = false;
    },
    cancelNewPass() {
      this.current_password = '';
      this.password = '';
      this.confirmpassword = '';
      this.loginInfo = true;
      this.resetPass = false;
      this.somethingWrongModal = false;
    },
    openPasswordInfo() {
      this.loginInfo = false;
      this.resetPass = true;
    },
    openEmailInfo() {
      this.loginInfo = false;
      this.editEmailInfo = true;
    },
    openLoginInfo() {
      this.newEmail = '';
      this.loginInfo = true;
      this.editEmailInfo = false;
    },
    sendVerificationLink() {
      let data = {
        email: this.newEmail,
        status: "all",
      };
      api()
        .post(`/member/app/validate/email`, data)
        .catch((err) => {
          if (err) {
            this.somethingWrongModal = true;
            return;
          }
        })
        .then((res) => {
          if (res) {
            if (res.data.valid) {
              api()
                .post(`/member/app/change/email`, { email: this.newEmail })
                .catch((err) => {
                  if (err) {
                    this.somethingWrongModal = true;
                    return;
                  }
                })
                .then((res) => {
                  if (res) {
                    this.linkSentModal = true;
                  }
                });
            } else {
              this.emailAlreadyExists = true;
            }
          }
        });
    },
    closeEmailExists() {
      this.emailAlreadyExists = false;
      this.newEmail = "";
    },
    checkEmail() {
      this.$refs.validEmail.validate();
      console.log("valid");
    },
    // This is for user/member eligibility check
    reconfirmEligibility() {
      this.$store.dispatch("getEmployerPlans");
      this.$router.push("/confirm-plan-information");
    },
    // This is to confirm eligibility for dependents
    confirmEligibility(familyDetails) {
      // alert(familyDetails.nextStep);
      console.log(familyDetails);

      // Step 1: Confirm Eligibility
      this.confirmingEligibility = true;

      let memberData = {
        subscriberRelation: familyDetails.subscriberRelation,
        firstName: familyDetails.firstName,
        lastName: familyDetails.lastName,
        dob: familyDetails.dob,
        gender: familyDetails.gender,
        memberId: familyDetails.memberId,
        planId: familyDetails.planId,
        id: familyDetails.id,
      };

      console.log(memberData);

      this.$store.commit("setFamilyMemberData", memberData);
      this.$router.push("/add-family-member");
    },
    selectProvider(famData) {
        // Check if there is an active attribution
        if (famData.subscription?.status === 'active') {
            this.cannotEnrollModal = true;
        } else  {
            this.$store.commit("setDependent", famData.id);
            this.$store.dispatch("getMarketplace", this.$route.query);
            this.$router.push("/marketplace");
        }
    },
    reloadPage() {
      window.location.reload();
    },
    getHomeInformation() {
      // api()
      //   .get(`/member/app/home`)
      //   .then((res) => {
      //     if (res) {
      //       this.providerId = res.data.providerId;
      //       this.locationId = res.data.locationId;
      //       api()
      //         .get(
      //           `/member/app/market/provider/${this.providerId}?locationId=${this.locationId}`
      //         )
      //         .then((res) => {
      //           if (res) {
      //             this.selectedProvider = res.data;
      //           }
      //         });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //   });
    },
    openAddNewMember() {
      // this.addMemberModal = true;
      // this.dependentModal = true;

      // Emptty the state.familyMemberData, first
      this.$store.commit("setFamilyMemberData", []);

      // Then go here
      this.$router.push("/add-family-member");
    },
    formatPhoneNumber(event) {
      this.newMember.phone = event;
      this.formattedPhoneNumber = this.formatAsPhoneNumber(
        this.newMember.phone
      );
    },
    confirmFormatPhoneNumber(event) {
      this.confirmMember.phone = event;
      this.confirmFormattedPhoneNumber = this.formatAsPhoneNumber(
        this.confirmMember.phone
      );
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value =
        value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
    // getaccountInformation() {
    //   api()
    //     .get(`/member/app/account`)
    //     .then((res) => {
    //       if (res) {
    //         console.log("PERSONAL DATA");
    //         console.log(res.data.personal);
    //         this.user = res.data.personal;
    //         this.user.nextStep = res.data.nextStep;
    //         this.role = res.data.subscriberRelation;
    //         this.newMember.companyName = this.user.companyName;
    //         this.newMember.planId = this.user.planID;
    //         this.newMember.payerId = this.user.payerID;
    //         this.newMember.groupId = this.user.groupID;
    //         //this.newMember.memberId = this.user.memberID;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // },
    getPlans() {
      api()
        .get(`/member/app/plans`)
        .then((res) => {
          if (res) {
            this.plans = res.data;
          }
        })
        .catch((err) => {
          if (err.response.data) {
            this.error = err.response.data;
            familyMemberDetails;
          } else {
            this.error = "Something went wrong. Please try again later.";
          }
        });
    },
    getFamily() {
      // api()
      //   .get(`/member/app/family/members`)
      //   .then((res) => {
      //     if (res) {
      //       this.family = res.data;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //   });
    },
    openPannel(pannelName) {
      this.somethingWrongModal = false;
      this.pannelName = pannelName;
      if (pannelName == "settings") {
        this.loginInfo = true;
        this.editEmailInfo = false;
        this.resetPass = false;
      }

      // this.$gtag.event("login", { method: "Google" });
    },
    familyMemberDetails(member) {
      this.memberInfo = true;
      this.memberInfoLoader = true;
      this.isActive = !this.isActive;
      api()
        .get(`/member/app/family/member/${member.id}`)
        .then((res) => {
          if (res) {
            // console.log("familyDetails: ", res.data);
            this.familyDetails = res.data;
            if (res.data.providerId && res.data.locationId) {
              api()
                .get(
                  `/member/app/market/provider/${res.data.providerId}?locationId=${res.data.locationId}`
                )
                .then((res) => {
                  if (res) {
                    this.dependentProvider = res.data;
                  }
                });
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          this.memberInfoLoader = false;
        });
    },

    memberToRemove(member) {
      this.removeItem = member;
      this.removeMemberDialog = true;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    removeMember() {
      this.removeMemberDialog = false;
      api()
        .delete(`/member/app/family/member/${this.removeItem.id}`)
        .then((res) => {
          if (res) {
            this.messages = true;
            this.removedMsg = res.data;
          }
        })
        .catch((err) => {
          if (err) {
            this.messages = true;
            this.errorMsg = err.response.data;
          }
        });
    },
    okayBtn() {
      this.messages = false;
      this.removedMsg = "";
      this.errorMsg = "";
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.v-tooltip__content.menuable__content__active {
  opacity: 1;
  background-color: #e6e6e6;
  color: #333333;
  width: 200px;
  margin: 10px;
  padding-bottom: 10px;
  margin-bottom: 100px !important;
  z-index: 2247483647 !important;
}
.no-list-style li {
  list-style-type: none !important;
}
.text-ellipsis {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 12px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card_logo_details {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid gray;
  background-color: white;
}

.panels-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Align panels to the right */
}

.right-panels {
  width: 100%;
  /* Ensure panels take up the full width */
}

.right-panel {
  width: 100%;
  /* Ensure each panel takes up the full width */
}

.active-card {
  transition: transform 0.3s;
  box-shadow: 10px 10px 40px grey;
}
</style>
