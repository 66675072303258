<template>
  <div class="login-page">
    <!-- CONTENT -->
    <div class="login-page-container">
      <div class="register-page-form height_auto">
        <!-- FORM SCROLLING -->
        <div class="form_scorlling_login">
          <!-- LOGO -->
          <div class="login_logo_container">
            <img src="/apaly-logo.png" height="64" width="auto" />
          </div>

          <!-- FORM 1 -->
          <v-form v-if="displayEmail" v-model.trim="valid" ref="formLogin" class="login_form_cutom">
            <div class="login_title">
              <div class="font-24 font-weight-400">Login</div>
              <div class="font-1">Enter your email to log in</div>
            </div>

            <div class="col-12 pl-0 pr-0">
              <label style="color: #333 !important; font-weight: 400">Email</label>
              <v-text-field placeholder="Enter email" outlined dense type="email" :messages="!emailExists
                  ? 'There is no account associated with that email.'
                  : !emailVerified
                    ? 'Email verification still pending.'
                    : showEmailExists ? 'This email is already registered. Please log in instead' : ''
                " @blur="lowerCaseEmail()" @keydown.enter.prevent="valid && nextStep()" v-model="email"
                :rules="emailRules" style="border-radius: 8px"></v-text-field>
              <div v-if="!emailExists" class="text-under-input" @click="$router.push({ name: 'MemberRegister' })">
                Switch to register >
              </div>
              <div v-else-if="!emailVerified" class="text-under-input" @click="resendVerificationLink">
                Resend verification link >
              </div>
            </div>
            <div class="form-button mt-2 col-12 pl-0 pr-0">
              <v-btn class="primary" :disabled="!valid" width="200px" @click="nextStep()"
                style="border-radius: 8px">Next</v-btn>
            </div>
          </v-form>

          <!-- FORM 2 -->
          <v-form v-else v-model.trim="valid" ref="formLogin" class="login_form_cutom">
            <div class="login_title">
                <img @click="backtoEmail()" role="button" src="/svg/backArrow.svg" />
                <div class="text-center" style="font-weight: bold; font-size: 18px;">
                    Logging in as <br> "{{ email }}"
                </div>
            </div>

            <div>
              <!-- <div class="col-12">
              <img @click="displayEmail = true" role="button" src="/svg/backArrow.svg">
              {{ email }}
            </div> -->

              <div v-if="!emailExists" class="text-under-input" @click="$router.push({ name: 'MemberRegister' })">
                Switch to register >
              </div>
              <div v-else-if="!emailVerified" class="text-under-input" @click="resendVerificationLink">
                Resend verification link >
              </div>
            </div>
            <div class="col-12">
              <label style="color: #333 !important; font-weight: 400">Password</label>
              <v-text-field hide-details outlined dense :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="password" :rules="passwordRules" style="border-radius: 8px"
                @keydown.enter.prevent></v-text-field>
              <p v-if="error.length > 0" class="error-text mb-0 pb-0">
                Invalid email and / or password
              </p>
              <!-- <div v-if="incorrectPassword" class="text-under-input" @click="navigateToConfirmEmail" >Reset Password ></div> -->
            </div>
            <div class="forget-password-text">
              <p class="text-right pass-text">
                <a @click="$router.push({ name: 'MemberForgotPassword' })">Forgot password?
                  <svg width="18" height="18" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.85789 3.76756L7.59273 4.03269C7.41701 4.20841 7.41701 4.49334 7.59273 4.6691L14.9237 12.0001L7.59273 19.3312C7.41701 19.5069 7.41701 19.7918 7.59273 19.9676L7.85789 20.2327C8.03362 20.4084 8.31855 20.4084 8.49427 20.2327L16.4087 12.3183C16.5844 12.1426 16.5844 11.8577 16.4087 11.6819L8.49427 3.76756C8.31855 3.5918 8.03362 3.5918 7.85789 3.76756Z"
                      fill="#0069F3" />
                  </svg></a>
              </p>
            </div>
            <div class="form-button col-12">
              <v-btn class="primary" :disabled="!valid" block @click="login()" style="border-radius: 8px">Log In</v-btn>
            </div>
            <div class="text-center">or</div>
            <div class="magic-link col-12">
              <v-btn outlined block color="#0069F3" @click="loginWithMagicLink()" :disabled="linkSent" width="280"
                style="border-radius: 8px">
                Send me a one-time login link
              </v-btn>
            </div>
            <div class="magic-link col-12">
              <div class="login_note">
                <!-- <img src="/svg/icon_info_circle_gray.svg"/>  -->
                For a password-free login, we'll e-mail you a login link.
              </div>
            </div>
          </v-form>
        </div>
        <!-- 
        <div class="login-page-footer mobile-footer col-12">
            Don't have an account?
            <div class="link-text" @click="$router.push('/register')">
              Sign Up for free
              <svg width="18" height="18" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.85789 3.76756L7.59273 4.03269C7.41701 4.20841 7.41701 4.49334 7.59273 4.6691L14.9237 12.0001L7.59273 19.3312C7.41701 19.5069 7.41701 19.7918 7.59273 19.9676L7.85789 20.2327C8.03362 20.4084 8.31855 20.4084 8.49427 20.2327L16.4087 12.3183C16.5844 12.1426 16.5844 11.8577 16.4087 11.6819L8.49427 3.76756C8.31855 3.5918 8.03362 3.5918 7.85789 3.76756Z"
                  fill="#0069F3" />
              </svg>
            </span>
          </div> -->

        <!-- DONT HAVE AN ACCOUNT? -->
        <!-- <div class="sign_footer_position">
          <div class="sign_footer">
            <span>Looking for providers?</span>
            <strong class="link-text" @click="$router.push('/marketplace')">
              &nbsp; Go to Marketplace &nbsp;
              <img src="/svg/registerArrow.svg" />
            </strong>
          </div>
        </div> -->

        <!-- DONT HAVE AN ACCOUNT? -->
        <!-- <div class="sign_footer_position" style="display: none;">
          <div class="sign_footer">
            <span>Don't have an account?</span>
            <strong class="link-text" @click="$router.push('/register')">
              &nbsp; Sign Up for free &nbsp;
              <img src="/svg/registerArrow.svg" />
            </strong>
          </div>
        </div> -->
      </div>
    </div>

    <!-- MODALS -->
    <v-dialog v-model.trim="linkSentModal" width="510" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="reload()">
              <v-icon>mdi-close</v-icon>
            </p>
          </span>
          <div class="display">
            <div class="text-center">
              <img src="/svg/email_sent.svg" />
            </div>
            <div style="font-size: 24px; color: #333333; margin-top: 20px">
              Check your E-mail
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              {{ linkMsg }}
            </div>
          </div>

          <div class="text-center text--white mt-6">
            <v-btn color="primary" class="email-sent" @click="reload()">
              Back To Login
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="exipredLinkModal" width="510" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="resendLink()">
              <v-icon>mdi-close</v-icon>
            </p>
          </span>
          <div class="display">
            <div style="font-size: 24px; color: #333333; margin-top: 20px">
              Your magic link has expired
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              Please request a new magic link or log in using your password
            </div>
          </div>

          <div class="text-center text--white mt-6">
            <v-btn color="#0069F3" class="text-white email-sent" @click="resendLink()">
              Send new magic link
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay v-model="redirectLoader" contained class="align-center justify-center">
      <v-progress-circular indeterminate :size="50" color="primary" class="text-center mt-5 mb-5"></v-progress-circular>
    </v-overlay>
  </div>
</template>
  
<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import MembersPlanModal from "./MembersPlanModal.vue";
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";

export default {
  data() {
    return {
      showPassword: false,
      emailExists: true,
      emailVerified: true,
      incorrectPassword: false,
      snackbar: false,
      textInSnackbar: "",
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "This field is required",
        // (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(v) || "Min. 8 characters with at least one capital letter, a number and a special character.",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      error: "",
      membersPlanModalStatus: false,
      selectedProvider: null,
      displayEmail: true,
      linkMsg: null,
      linkSentModal: false,
      exipredLinkModal: false,
      linkSent: false,
      redirectLoader: false,
      showEmailExists: false,
    };
  },
  components: {
    mainHeader,
    MembersPlanModal,
  },
  methods: {
    backtoEmail() {
      this.displayEmail = true;
      this.password = "";
      this.error = "";
    },
    resendLink() {
      window.location = "/login";
    },
    reload() {
      window.location.reload();
    },
    nextStep() {
      if (this.valid) {
        this.displayEmail = false;
      } else {
        //nothing
      }
    },
    loginWithMagicLink() {
      this.linkSent = true;
      api()
        .post(`member/app/request/magic/link`, { email: this.email })
        .then((res) => {
          if (res) {
            this.linkMsg = res.data.message;
            this.linkSentModal = true;
          }
        })
        .catch((err) => {
          if (err) {
            console.log("loginWithMagicLink() Error: ", err);
          }
        })
        .finally(() => {
          this.linkSent = false;
        });
    },
    goToApaly() {
      window.location = "https://apaly.net/login";
    },
    lowerCaseEmail() {
      this.email = this.email.toLowerCase();
    },

    checkEmail() {
      api()
        .get(`/member/app/verify?email=${this.email}`)
        .then((res) => {
          if (res.data.exist === false) {
            this.emailExists = false;
          } else {
            this.emailExists = true;
          }

          if (res.data.verified === false) {
            this.emailVerified = false;
          } else {
            this.emailVerified = true;
          }
        })
        .catch((err) => { });
    },

    resendVerificationLink() {
      api()
        .get(`/member/app/send/verification?email=${this.email}`)
        .then((res) => {
          if (res) {
            this.snackbar = true;
            this.textInSnackbar =
              "Verification link sent to email " + this.email;
          }
        })
        .catch((err) => {
          console.log("resendVerificationLink() Error: ", err);
        });
    },
    async login() {
  this.$refs.formLogin.validate();

  if (this.valid) {
    if (this.$cookies.get("token")) {
      this.$cookies.remove("token");
    }

    let data = {
      email: this.email,
      password: this.password,
    };

    try {
      const res = await api().post(`/member/app/login`, data);
      
      if (res) {
        this.$cookies.set("token", res.data.accessToken);
        
        let filterMarket = res.data.filterMarket;
        let companyFilter = filterMarket && filterMarket.company ? filterMarket.company : null;
        let url = `/marketplace`;

        const queryParams = new URLSearchParams();

        if (filterMarket) {
            if (companyFilter) {
                queryParams.append('company', companyFilter);
            }
          let locationFilter = filterMarket.location ? filterMarket.location : null;
          if (locationFilter)
            queryParams.append('location', locationFilter);
        }

        if (res.data.nextStep && res.data.nextStep == "confirmPlan") {
          await this.$store.dispatch("getEmployerPlans");
          this.$router.push("/confirm-plan-information");
        } else if (res.data.nextStep && res.data.nextStep == "selectDPC") {
          window.location = `${url}${queryParams ? queryParams.toString() : ''}`;
        } else if (res.data.nextStep && res.data.nextStep == "complete") {
          window.location = "/home";
        }

        // Dispatch memberInfo after all other actions
        await this.$store.dispatch("getMemberInfo");
      }
    } catch (err) {
      if (err.response.data) {
        this.error = err.response.data;
      } else {
        this.error = "Something went wrong. Please try again later.";
      }

      this.snackbar = true;
      this.textInSnackbar = err.response.data;
    }
  }
},

    navigateToConfirmEmail() {
      this.$store.commit("setEmail", this.email);
      this.$router.push("/auth/confirm-email-password");
    },
    closeMemberPlanModal() {
      this.membersPlanModalStatus = false;
    },
  },
  created() {
    localStorage.clear();
    this.$cookies.remove("token");
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Member Login", // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
    this.selectedProvider = this.$store.getters.getSelectedProvider;
    if (this.$route.query.magicLink) {
      this.redirectLoader = true;
      api()
        .post(`/member/app/login/magic/link`, {
          token: this.$route.query.magicLink,
        })
        .then((res) => {
          if (res) {
            // res.data.type = 'dpc-member'; // Why do this? K.N
            // localStorage.setItem("user", JSON.stringify(res.data)); // We'll no lomger do this

            // Set token
            this.$cookies.set("token", res.data.accessToken);
            // Dispatch memberInfo
            this.$store.dispatch("getMemberInfo");

            let filterMarket = res.data.filterMarket;
            let companyFilter =
              filterMarket && filterMarket.company
                ? filterMarket.company
                : null;
            let url = `/marketplace`;
            if (filterMarket) {
              url += `?${companyFilter ? "company=" + companyFilter : ""}`;
              let locationFilter = filterMarket.location
                ? filterMarket.location
                : null;
              if (locationFilter)
                url += `${companyFilter ? "&" : ""}location=${locationFilter}`;
            }
            if (res.data.nextStep && res.data.nextStep == "confirmPlan") {
              window.location = "/confirm-plan-information";
            } else if (res.data.nextStep && res.data.nextStep == "selectDPC") {
              window.location = url;
            } else if (res.data.nextStep && res.data.nextStep == "complete") {
              window.location = "/home";
            }
          }
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.exipredLinkModal = true;
          }
          this.snackbar = true;
          this.textInSnackbar = err.response.data;
        })
        .finally(() => {
          this.redirectLoader = false;
        });
    }
    if (this.$route.query.email) {
        this.email = this.$route.query.email;
        this.showEmailExists = true;
    }
  },
};
</script>
<style scoped lang="scss">
.subtitle-style {
  width: 500px;
}

.hide-desktop {
  display: none !important;
}

label {
  color: #333333 !important;
  font-weight: 400;
}

.error-text {
  color: red;
}

.magic-link {
  justify-content: center;
  display: flex;
}

.provider-logo {
  height: 56px;
  width: 80px;
  background-color: white;
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  left: 8px;
  top: 40px;
  background-size: contain;
  background-position: center;
}

.login-page-header {
  display: flex;
}

.apaly-logo {
  width: 160px;
  height: 70px;
}

.login-page-container {
  margin: 0 auto;
}

.login-page-logo {
  text-align: center;
}

.login-page-title {
  margin-top: 20px;
  text-align: start;
}

.login-page-footer {
  margin-top: 10px;
}

.login-page-error-box {
  padding: 20px 30px;
}

.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;

  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }

  &-logo {
    img {
      width: 120px;
    }
  }

  &-text {
    color: white;
  }

  &-footer-text {
    color: white;
  }
}

.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.authentication-page-right-side-form {
  align-self: center;
  padding: 0 200px;
}

.form-button {
  display: flex;
  justify-content: flex-end;
}

.link-text {
  color: #0069f3;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.pass-text {
  color: #0069f3;
  font-weight: 700;
  font-size: 16px;
}

.mobile-footer {
  margin-top: 0px !important;
}

.mobile-footer-2 {
  margin-top: 0px !important;
}

@media only screen and (max-width: 600px) {
  .apaly-logo {
    height: 60px;
    width: 120px;
  }

  .hide-desktop {
    display: flex !important;
  }

  .hide-mobile {
    display: none !important;
  }

  .mobile-footer {
    margin-top: 43vh !important;
  }

  .mobile-footer-2 {
    margin-top: 30vh !important;
  }

  .login-page-header {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .login-page-container {
    width: 100%;
  }

  .login-page-footer {
    padding: 0;
    margin-top: 30px;
  }

  .login-page-error-box {
    padding: 10px 0;
  }

  .authentication-page-right-side-form {
    padding: 0;
  }

  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>