<template>
    <!-- HEADER -->
    <div class="no-auth-header mobile_view_header_container" :style="`${loggedIn ? '':'display:none;'}`">
      <div  class="no-auth-header--left-side" > 
        <div class="show-on-mobile" @click="showNavigationDrawer()">
          <img src="/svg/menu_bars.svg" />
        </div>
        <div class="no-auth-header--left-side-name">
          <span class="hide-on-mobile d-flex pl-2"><img src="/apaly-logo.png" height="40px"> </span>
        </div>
      </div>
    <div class="no-auth-header--right-side d-flex">
   
      <div class="no-auth-header--right-side-buttons d-flex" role="button">
        <v-menu
          v-model="menu"
          bottom
          right
          offset-y
          origin="top right"
          transition="scale-transition"
        >
          <!-- Initials Logo -->
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-on="on" class="mr-1">
              <v-avatar size="30" v-bind="attrs" v-on="on" color="#4072B7">
                <span class="white--text" v-if="memberInfo && memberInfo.firstName && memberInfo.lastName">
                  {{ memberInfo.firstName.charAt(0) }}
                </span>
              </v-avatar>
            
            </v-btn>
            
          </template>
          
          <!-- Menu Dropdown -->
          <v-list>
            <v-list-item v-for="(item, i) in memberInfo.family" :key="i" >
              <!-- Member list -->
              <v-list-item-title class="pointer">
                <div class="header_members">

                  <div v-if="item.provider" class="header_col" @click="goToMemberAcc(item)">
                    <div class="header_col mr-5" style="text-align: left;">
                      {{ item.firstName }} {{item.lastName}}
                    </div>
                    <div class="header_col" style="text-align: right;">
                      <div class="header_member_provider">
                        {{ item.provider }}
                      </div>
                    </div>
                  </div>

                  <div v-else class="header_col" >
                    <div class="header_col mr-5" style="text-align: left;" @click="goToMemberAcc(item)">
                      {{ item.firstName }} {{item.lastName}}
                    </div>
                    <div class="header_col" style="text-align: right;" @click="selectProvider(item)">
                      <div class="header_member_provider" style="color: #990101;">
                        Select Provider
                      </div>
                    </div>
                  </div>


                </div>
              </v-list-item-title>
            </v-list-item>

            <!-- Divider -->
            <v-divider horizontal class="ml-5 mr-5 mt-2 mb-2" v-if="memberInfo.family"></v-divider>

            <!-- Add A Family Member -->
            <v-list-item class="pointer font-weight-600" @click="addNewFamilyMember()">
              <v-list-item-title class="display_flex align-center" style="color: #0069F3">
                Add a family member 
                &nbsp; <img src="/svg/icon_plus.svg" />
              </v-list-item-title>
            </v-list-item>
            
            <!-- Sign Out  -->
            <v-list-item class="pointer font-weight-600" @click="logout()">
              <v-divider horizontal class="mb-2"></v-divider>
              <v-list-item-title>
                Sign Out
              </v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>

      </div>

      <div class="mt-1" role="button"     @click="menu = true">
         <img style="width: 25px; height: auto" :src="`/svg/chevron_down.svg`" />
      </div>
    
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      drawer: null,
      menu: false,
      showComponents: false,
      profileImg: "",
      // user: {},
      // family: [],
      // signOut: ['Sign Out'],
    //  items: ['Sign Out'],
    //  currentMember: {},
    };
  },
  components:{
  },
  watch: {
    // getComponentState is not being used, so just comment out the watch code

    // getComponentState() {
    //   this.showComponents = localStorage.getItem("user") && this.$cookies.get("token");
    // },
  },
  computed: {
    ...mapState({
      memberInfo: (state) => state.memberInfo 
    }),
    loggedIn() {
      if(this.$cookies.get("token")) {
        return true
      }
      else {
        return false
      }
    },
  },
  created() {
    // this.getFamily(); // We don't use this.items anymore for the header dropdown
    // Will no longer use this
    // api()
    //     .get(`/member/app/member/info`)
    //     .then((res) => {
    //       if (res) {
    //         this.user = res.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });

    // if ( localStorage.getItem("user") && this.$cookies.get("token")) {
    //    this.showComponents = true;
    //   }
    //   else{
    //     this.showComponents = false;
    //   }
    
  },
  methods: {
    selectProvider(famData) {
      // Just copied this from Details page
      this.$store.commit("setDependent", famData.id);
      this.$store.dispatch('getMarketplace', this.$route.query); // K.N idk what this.$route.query is for
      this.$router.push("/marketplace");
    },
    addNewFamilyMember() {

      // Emptty the state.familyMemberData, first
      this.$store.commit("setFamilyMemberData", []);

      // Then go here
      this.$router.push("/add-family-member");
    },
    // getFamily(){
    //   api().get(`/member/app/family/members`).then((res)=>{
    //     if(res){
    //      this.family = res.data;
    //      if(this.family){
    //         this.items = [...this.family,...this.signOut];
    //      }
    //      else{
    //       this.items = [...this.signOut];
    //      }
       
    //     }
    //   }).catch((err)=>{
    //     console.log('err',err)
    //   });
    // },
    goToMemberAcc(item){
      // Remove Dependent ID from Store
      this.$store.commit("setDependent", "");

      api().get(`/member/app/token/dependent/${item.id}`).then((res)=>{
        if(res){
          // this.currentMember = res.data;
          // res.data.type = 'dpc-member'; // Why do this? K.N
          // localStorage.setItem("user", JSON.stringify(res.data)); // We no longer use this

          // Dispatch getMemberInfo
          this.$store.dispatch('getMemberInfo');

          this.$cookies.set("token", res.data.accessToken);
          window.location = '/home';
        }
      }).catch((err)=>{
        console.log('err', err)
        console.log('err', err.response.data)
      });
    },
    // goToRoute(link) { // No one's using this K.N
    //   if (link.includes("login")) {
    //     this.logout();
    //   } else {
    //     this.$route.path != link ? this.$router.push(link) : "";
    //   }
    // },
    showNavigationDrawer() {
      this.drawer = !this.drawer;
      this.$emit("drawer", this.drawer);
    },
    logout() {
      localStorage.clear();
      this.$cookies.remove("token");
      this.$store.commit("setComponentState", false);
      this.$router.push("/login");
    },
  },
  // mounted() {
  //   let Script = document.createElement("script");
  //   Script.setAttribute("src", "https://desk.zoho.com/portal/api/web/inapp/318353000002054081?orgId=675143780");
  //   Script.setAttribute("nonce", "");
  //   Script.setAttribute("defer", "defer");

  //   document.head.appendChild(Script);
  // }
};
</script>
<style scoped lang="scss">
.no-auth-header {
  box-shadow: 0 1px 1px #cccc;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  &--left-side {
    display: flex;
    align-items: center;
    &-logo {
      padding: 0 50px;
      border-right: 1px solid #ccc;
      img {
        width: 100px;
      }
    }
    &-name {
      font-size: 20px;
      span {
        //padding: 0 50px;
      }
    }
  }
  &--right-side {
    &-buttons {
      button {
        margin-left: 10px;
        border-radius: 10px;
      }
    }
  }
}
.show-on-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .show-on-mobile {
    display: flex;
  }
  .no-auth-header {
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    &--left-side {
      display: flex;
      align-items: center;
      &-logo {
        padding: 0 30px;
        border-right: 1px solid #ccc;
        img {
          width: 80px;
        }
      }
      &-name {
        font-size: 13px;
        span {
          padding: 0 10px 0 30px;
        }
      }
    }
    &--right-side {
      &-buttons {
        button {
          margin-left: 10px;
          border-radius: 10px;
        }
      }
    }
  }
  .hide-on-mobile {
    display: none !important;
  }
}
</style>
