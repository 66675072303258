<template>
  <div class="page-background">
    <main-header :title="member.firstName+' '+ member.lastName" :color="'#001E46'" :icon="'edit'" @editFromHeader="editMember"></main-header>
    <div class="mobile-content-background" style="overflow-y: auto" overflow-auto>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item>
            <v-list-item-content style="padding-bottom: 80px">
              <v-card style="background: #ebf1f9; margin-bottom: 15px">
                <v-card-title style="padding-bottom: 0">
                  Family Member’s Information
                </v-card-title>
                <div class="row">
                  <div class="col-6">
                    <v-card-subtitle style="padding-bottom: 0">
                      First Name
                      <br />
                      <span class="second-subtitle">{{ member.firstName }}</span>
                    </v-card-subtitle>
                  </div>
                  <div class="col-6">
                    <v-card-subtitle style="padding-bottom: 0">
                      Last Name
                      <br />
                      <span class="second-subtitle">{{ member.lastName }}</span>
                    </v-card-subtitle>
                  </div>
                </div>
                <div class="row" style="margin-top: 0">
                  <div class="col-6">
                    <v-card-subtitle>
                      Date of Birth
                      <br />
                      <span class="second-subtitle">{{ member.dob  | moment.utc("DD/MM/YYYY") }}</span>
                    </v-card-subtitle>
                  </div>
                  <div class="col-6">
                    <v-card-subtitle>
                      Gender
                      <br />
                      <span class="second-subtitle">{{member.gender == 'm' ? 'Male' : 'Female' }}</span>
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>

              <v-card style="background: #ebf1f9; margin-bottom: 15px">
                <v-card-title style="padding-bottom: 0"> Contact </v-card-title>
                <div class="row">
                  <div class="col-12">
                    <v-card-subtitle style="padding-bottom: 0">
                      Email
                      <br />
                      <span class="second-subtitle">{{ member.email }}</span>
                    </v-card-subtitle>
                  </div>
                </div>
                <div class="row" style="margin-top: 0">
                  <div class="col-6">
                    <v-card-subtitle>
                      Phone
                      <br />
                      <span class="second-subtitle">{{ member.phone }} </span>
                    </v-card-subtitle>
                  </div>
                  <div class="col-6">
                    <v-card-subtitle>
                      Home Zip Code
                      <br />
                      <span class="second-subtitle">{{ member.zipCode }}</span>
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>

              <v-card style="background: #ebf1f9; margin-bottom: 15px">
                <v-card-title style="padding-bottom: 0"> Provider </v-card-title>
                <div class="row">
                  <div class="col-12">
                    <v-card-subtitle style="padding-bottom: 0">
                      Provider
                      <br />
                      <span class="second-subtitle">Everside Health</span>
                    </v-card-subtitle>
                  </div>
                </div>
                <div class="row" style="margin-top: 0">
                  <div class="col-12">
                    <v-card-subtitle>
                      Location
                      <br />
                      <span class="second-subtitle">Main Street West</span>
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>

              <v-card style="background: #ebf1f9; margin-bottom: 15px">
                <v-card-title style="padding-bottom: 0">
                  Insurance Information
                </v-card-title>
                <div class="row">
                  <div class="col-6">
                    <v-card-subtitle style="padding-bottom: 0">
                      Member ID
                      <br />
                      <span class="second-subtitle">{{ member.memberId }}</span>
                    </v-card-subtitle>
                  </div>
                  <div class="col-6">
                    <v-card-subtitle style="padding-bottom: 0">
                      Group ID
                      <br />
                      <span class="second-subtitle">{{ member.groupId }}</span>
                    </v-card-subtitle>
                  </div>
                </div>
                <div class="row" style="margin-top: 0">
                  <div class="col-6">
                    <v-card-subtitle>
                      Payer ID
                      <br />
                      <span class="second-subtitle">{{ member.payerId }}</span>
                    </v-card-subtitle>
                  </div>
                  <div class="col-6">
                    <v-card-subtitle>
                      Employer
                      <br />
                      <span class="second-subtitle">{{loggedMember.firstName}} {{loggedMember.lastName}}</span>
                    </v-card-subtitle>
                  </div>
                </div>
                <div class="row" style="margin-top: 0">
                  <div class="col-10" style="padding-top: 0">
                    <v-card-subtitle>
                        Relationship to Subscriber
                         <br />
                      <span class="second-subtitle">{{ member.subscriberRelation }}</span>
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";

export default {
  data() {
    return {
      member: {},
      loggedMember:JSON.parse(localStorage.getItem("user")) ,
      id: this.$route.params.id,
    };
  },
  components: {
    mainHeader,
  },
  created() {
    this.getMember();
  },
  methods: {
    editMember() {
      this.$router.push(`/account/family-members/edit/${this.id}`);
    },
    getMember() {
      api()
        .get(`/member/app/family/member/${this.id}`)
        .then((res) => {
          if (res) {
            console.log("res", res);
            this.member = res.data;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.mobile-content-background {
  background-color: white;
  height: 100vh;
}
.mobile-content {
  padding: 16px 24px 0 24px;
}
.page-background {
  background-color: #e8e8e8;
}
.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-subtitle {
  font-family: "Quicksand";
  font-weight: 600;
}

.second-subtitle {
  color: #001e46;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
</style>