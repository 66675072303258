<template>
  <div class="register-page">
    <!-- CONTENTS -->
    <div class="register-page-container">
      <div class="register-page-form">
        <!-- HEADER -->
        <div class="sign_header_container">
          <div class="sign_header">
            <!-- BACK BUTTON -->
            <div class="sign_header_back">
              <img
                src="/svg/backArrow.svg"
                height="40"
                role="button"
                @click="$router.go(-1)"
              /><br />
            </div>

            <!-- LOGO -->
            <div class="sign_header_logo">
              <img src="/apaly-logo.png" height="60" width="auto" />
            </div>
          </div>
        </div>

        <!-- FORM -->
        <div class="form_desk_up">
          <v-form
            v-model.trim="valid"
            ref="form"
            class="authentication-page-right-side-form form_margin_bottom"
          >
            <!-- PROVIDER DATA -->

            <div class="provider_container" v-if="selectedProvider">
              <!-- LOGOS -->
              <div class="provider_logo_container">
                <div
                  class="provider_logo"
                  :style="`background-image:url(${selectedProvider.logo})`"
                ></div>
              </div>

              <span class="provider_location">
                <span class="font-16 provider_title">{{
                  selectedProvider.companyName
                }}</span>
                <br />
                <span class="font-12 provider_location">
                  {{ selectedProvider.contact?.address1 }},
                  {{ selectedProvider.contact?.city }},
                  {{ selectedProvider.contact?.state }},
                  {{ selectedProvider.contact?.zipCode }}
                </span>
              </span>
            </div>

            <p class="mb-0" style="font-size: 36px; font-weight: 600">
              Last Step
            </p>
            <p
              class="mb-8 text-gray"
              style="font-size: 16px; font-weight: 400; color: #333333"
            >
              Let's confirm your health plan eligibility
            </p>
            <div class="font-20 mb-5">Personal Information</div>
            <div>
              <label>First Name</label>
              <v-text-field
                placeholder="Enter First Name"
                outlined
                dense
                v-model.trim="member.firstName"
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div>
              <label>Last Name</label>
              <v-text-field
                placeholder="Enter Last Name"
                outlined
                dense
                v-model.trim="member.lastName"
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div>
              <div>
                <label>Mobile Phone Number</label>
                <v-text-field
                  v-model="formattedPhoneNumber"
                  maxlength="12"
                  @input="formatPhoneNumber"
                  placeholder="Enter Mobile Phone Number: (999) 999-9999"
                  :rules="phoneRules"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>

            <!-- FOOTER BUTTONS -->

            <div class="dialog_buttons mt-6">
              <button
                class="dialog_double_button dialog_btn_second dialog_btn_floatLeft"
                style="border: 0 !important"
                @click="$router.go(-1)"
              >
                Cancel
              </button>
              <button
                class="dialog_double_button dialog_btn_primary dialog_btn_floatRight"
                :disabled="!valid"
                @click.prevent="confirm()"
              >
                Confirm Information
              </button>
            </div>

            <!-- <div class=" mt-1 justify-center text-center">
              <v-btn
                color="#0069F3"
                text
                width="170px"
                @click="$router.go(-1)"
                >Cancel</v-btn>
              <v-btn
                style="color: white"
                color="#0069F3"
                :disabled="!valid"
                width="300px"
                @click="confirm()"
                >Confirm Information</v-btn>
            </div> -->
          </v-form>
        </div>
        <!-- <img class="apaly-logo hide-mobile ml-auto" src="/apaly-logo.png" height="64"> -->
      </div>
    </div>

    <!-- MODALS -->

    <!-- ALL SET -->
    <v-dialog v-model="success" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="$router.push('/home')"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">Great! You're all set.</div>
          <div class="dialog_subtext dialog_second">
            A representative will contact you to start your membership with this
            clinic.
          </div>
          <div class="dialog_buttons">
            <button
              class="dialog_single_button dialog_btn_primary"
              @click="$router.push('/home')"
            >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="success" width="500px" persistent>
      <v-card class="pa-2">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="$router.push('/login')"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          
          <div class="font-24 font-weight-400 text-start mb-3" style="color: #014d00">Great! You're all set</div>
          <div class="font-16 font-weight-400 text-start " style="color: #333333">A representative will contact you to start your membership with this clinic </div>

        </v-card-text>
      <div class="justify-center col-12 text-center mb-2"> 
        <v-btn
      color="#0069F3"
      style="color: white"
      @click="$router.push('/login')"
      >Okay
      </v-btn></div>
      </v-card>
    </v-dialog> -->
    <errorModal
      v-if="somethingWrong"
      :someThingWentWrong="somethingWrong"
      ref="error_modal"
    ></errorModal>
  </div>
</template>
    
<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "@/services/api";
import moment from "moment";
import errorModal from "../../../Common/SomethingWentWrong.vue";

export default {
  components: {
    errorModal,
  },
  data() {
    return {
      somethingWrong: false,

      allSetModal: false,
      dependentModal: false,
      memberErrorMsg: "",
      user: {},
      informativeModal: false,
      hasFamily: false,
      providerId: null,
      locationId: null,
      checkingEligibility: false,
      chooseProvider: false,
      success: false,
      errorModal: false,
      dateFormatted: "",
      formattedPhoneNumber: "",
      selectedProvider: null,
      checkEmailDialog: false,
      filterMarket: {},
      member: {
        // Why dob is initialized? it should only fill in by BE (T.N.)
        // dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      showPassword: false,
      showPasswordConfirmation: false,
      valid: false,
      emailErrorMessage: null,
      emailStatus: false,
      pdfUrl: "",
      pdfDialog: false,
      subscriberRelation: "",
      plans: [],
      requiredRules: [(v) => !!v || "This field is required"],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      passwordMatches: true,
      error: "",
      eligibilityStep: 1,
      validInfo: false,
      validInsurance: false,
      confirmSemafor: true,
      addMemberSemafor: true,
      enrollmentStatus: false,
      selectedProvider: null,
    };
  },
  methods: {
    confirm() {
      // Problem no. 1: v-form by default, reloads the page after clicking a button. Using @click.prevent fixes the issue
      // Problem no. 2: Weird empty modal pops up. It was intended to show the error but fails.
      // The reason it fails is due to employerId, by nature is undefined since we are registering an inactive employer.

      let data = {
        email: this.$store.state.DPCMember.registrationData.email,
        employerId: this.$store.state.DPCMember.registrationData.employerId,
        firstName: this.member.firstName,
        lastName: this.member.lastName,
        phone: this.member.phone,
      };
      api()
        .post(`/member/app/signup`, data)
        .then((res) => {
          if (res) {
            this.$cookies.set("token", res.data.accessToken);
            res.data.type = "dpc-member";
            localStorage.setItem("user", JSON.stringify(res.data));
            this.success = true;
          }
        })
        .catch((err) => {
          // this.$swal(err.response.data, "", "error");
          this.somethingWrong = true;
        });
    },
    navigateTo(link) {
      this.$router.push({ name: link });
    },
    formatPhoneNumber(event) {
      this.member.phone = event;
      this.formattedPhoneNumber = this.formatAsPhoneNumber(this.member.phone);
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value =
        value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
  },
  watch: {},
  created() {
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Member Confirm Information", // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
    this.selectedProvider = this.$store.getters.getSelectedProvider;
  },
};
</script>
<style scoped lang="scss">
.sign_header {
  // padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 75px !important;
}
label {
  color: #333333 !important;
  font-weight: 400;
}
.logo-style {
  display: block !important;
}

.v-input__control {
  margin-bottom: 0px !important;
}

.apaly-logo {
  width: 130px;
}
.register-page-container {
  margin: 0 auto;
}
.register-page-logo {
  left: 30px;
}
.register-page-title {
  text-align: center;
}
.register-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}
.register-page-error-box {
  padding: 20px 140px;
}
.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
.link-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .apaly-logo {
    height: 40px;
    width: 70px;
  }
  .hide-desktop {
    display: flex !important;
  }
  .hide-mobile {
    display: none !important;
  }
  .logo-style {
    display: flex !important;
    justify-content: space-between;
  }
  .router-view {
    padding-top: 0px !important;
  }
  .register-page-logo {
    position: inherit;
  }
  .register-page {
    padding: 24px;
    margin-top: 0px;
  }
  .register-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .register-page-container {
    width: 100%;
  }
  .register-page-footer {
    padding: 0;
    margin-top: 20px;
  }
  .register-page-error-box {
    padding: 10px 0;
  }
  .authentication-page-right-side-form {
    width: 100%;
    padding: 0;
  }
  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
    