<template>
<div class="full_loader_bg">
    <div class="full_loader_container">
        <div class="full_loader_circle"></div>
        <img class="full_loader_logo" src="/apaly_circle_logo.png">
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>